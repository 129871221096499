import Head from 'next/head';

import { PAGES_WHERE_COOKIE_BANNER_IS_NOT_DISPLAYED } from './pages-where-cookie-banner-not-appear';

// utils
import { bool } from '../config/env';

export function UsercentricsScripts({ server, globals, pageUrl }) {
  const isPreview = bool(server?.isPreview);
  const ucSettingsId = globals?.uc_settingsId;

  // user has confirmed the cookie banner by clicking on "save" or "accept all"
  const isConsentGiven =
    typeof UC_UI !== 'undefined' &&
    UC_UI?.getServicesBaseInfo()
      .flatMap((service) => service.consent.history)
      .filter((service) => service.action === 'onUpdateServices')
      .some((service) => service.status);

  const notShowCookieBanner = PAGES_WHERE_COOKIE_BANNER_IS_NOT_DISPLAYED.some(
    (page) => pageUrl?.match(`.*/${page}$`),
  );
  const showCookieBanner = !notShowCookieBanner && !isConsentGiven;

  return (
    <Head>
      {notShowCookieBanner && (
        <script
          dangerouslySetInnerHTML={{
            __html: `   
             var UC_UI_SUPPRESS_CMP_DISPLAY = true;
            if (typeof UC_UI !== 'undefined') {
              setTimeout(()=> {
              UC_UI.closeCMP();
              }, 500)
            }
          `,
          }}
        />
      )}
      {showCookieBanner && (
        <script
          dangerouslySetInnerHTML={{
            __html: `  
              var UC_UI_SUPPRESS_CMP_DISPLAY = undefined;
              if (typeof UC_UI !== 'undefined') {
                setTimeout(()=> {
                  UC_UI.showFirstLayer();
                }, 500)
              }  
            `,
          }}
        />
      )}

      {Boolean(ucSettingsId) && (
        <>
          <link rel="preconnect" href="https://app.usercentrics.eu" />
          <link rel="preconnect" href="https://api.usercentrics.eu" />
          <link
            rel="preload"
            href="https://app.usercentrics.eu/browser-ui/latest/loader.js"
            as="script"
          />
          <link rel="dns-prefetch" href="https://app.usercentrics.eu" />
          <link rel="dns-prefetch" href="https://api.usercentrics.eu" />
          <script id="testsimon2" />
          <script
            type="application/javascript"
            id="usercentrics-cmp"
            src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
            data-settings-id={ucSettingsId}
            data-version={isPreview ? 'preview' : undefined}
            async
          />
        </>
      )}
    </Head>
  );
}
