import currentLanguageSelector from './languageSelectors';
import { ProductProduct } from 'components/Product/reducer.types';
import { isNotEmpty } from '../is-not-empty';

// Get the current catalog language
export const currentCatalogSelector = (state: AppState): string | null => {
  const currentLanguage = currentLanguageSelector(state);
  return state.globals[currentLanguage ?? '']?.onlineCatalog?.locale.current ?? null;
};

/**
 * Check if paremeter state with key and attribute is available
 *
 * @param state
 * @param currentCatalog
 * @param parameter
 * @param key
 * @param attribute
 * @returns {null}
 */
const isStateParameterAvailable = (state, currentCatalog, parameter, key, attribute) =>
  currentCatalog &&
  !!key &&
  state.productCatalogs?.[currentCatalog]?.[parameter]?.[key]?.[attribute];

export const productsByProductKeysSelector = (state, productKeys: string[]): ProductProduct[] => {
  const currentCatalog = currentCatalogSelector(state);

  return productKeys
    .map((productKey) => productByKeySelector(state, productKey, currentCatalog))
    .filter(isNotEmpty) as ProductProduct[];
};

export const productByKeySelector = (state: AppState, productKey, catalog?) => {
  const currentCatalog = catalog || currentCatalogSelector(state);

  if (!productKey || !currentCatalog) return null;
  if (!state.productCatalogs[currentCatalog]) return null;
  if (!state.productCatalogs[currentCatalog].products) return null;

  return state.productCatalogs[currentCatalog]?.products?.[productKey] || null;
};

export const productsByProductKeysCatalogSelector = (state, productKeys) => {
  return productKeys
    .map((productKey) =>
      productByKeySelector(state, productKey.split(':')[0], productKey.split(':')[1]),
    )
    .filter(isNotEmpty);
};

export const categoryProductsByProductKeySelector = (state, categoryId) => {
  const currentCatalog = currentCatalogSelector(state);
  const hasProperty = isStateParameterAvailable(
    state,
    currentCatalog,
    'categories',
    categoryId,
    'productKeys',
  );

  if (currentCatalog && hasProperty) {
    const { productKeys } = state.productCatalogs[currentCatalog].categories[categoryId];
    return productKeys.map((product) => productByKeySelector(state, product));
  }

  return null;
};

export const totalCategoryProductsByProductKeySelector = (state, categoryId) => {
  const currentCatalog = currentCatalogSelector(state);
  const hasProperty = isStateParameterAvailable(
    state,
    currentCatalog,
    'categories',
    categoryId,
    'total',
  );

  if (currentCatalog && hasProperty) {
    return state.productCatalogs[currentCatalog].categories[categoryId].total;
  }

  return null;
};

export const isSectionLoadedBySectionIdSelector = (state, sectionId) => {
  const catalog = currentCatalogSelector(state);

  if (!catalog) return null;
  if (!state.productCatalogs) return null;
  if (!state.productCatalogs[catalog]) return null;
  if (!state.productCatalogs[catalog].sections) return null;
  if (!state.productCatalogs[catalog].sections[sectionId]) return null;
  return state.productCatalogs[catalog].sections[sectionId].isFetching;
};

export const categoryByIdSelector = (state, categoryId) => {
  const catalog = currentCatalogSelector(state);
  if (!catalog) return null;
  if (!categoryId) return null;
  if (!state.productCatalogs) return null;
  if (!state.productCatalogs[catalog]) return null;
  if (!state.productCatalogs[catalog].categories) return null;
  return state.productCatalogs[catalog].categories[categoryId] || null;
};

export const categoriesByIdsSelector = (state, categoryItems) => {
  if (!categoryItems) return null;

  return categoryItems.map((category) => categoryByIdSelector(state, category.categoryCatalogId));
};
