import { useContext } from 'react';

// components
import { NavigationContext, NAVIGATION_OPEN } from '../../NavigationContext';

// styles
import styles from './main-navigation-mobile.scss';
import Overlay from './OffCanvas/Overlay';
import OffCanvasWrapper from './OffCanvas/OffCanvasWrapper';
import FakeShadow from './OffCanvas/FakeShadow';

interface MainNavigationMobileProps {
  items: any[];
  handleClick: any;
  pageType?: string | null;
  translationToOverview?: string | null;
  isCampus?: boolean;
}

function MainNavigationMobile({
  items,
  handleClick,
  translationToOverview = null,
  isCampus = false,
  pageType = null,
  ...props
}: Readonly<MainNavigationMobileProps>) {
  const {
    state: { flyoutState, languageShown },
  } = useContext(NavigationContext);

  return (
    <div
      className={`${styles.mobileWrapper} ${
        flyoutState === NAVIGATION_OPEN && languageShown ? 'language--shown' : 'language--hidden'
      }`}
    >
      <Overlay />
      <FakeShadow />
      <OffCanvasWrapper
        translationToOverview={translationToOverview}
        items={items}
        handleClick={handleClick}
        pageType={pageType}
        isCampus={isCampus}
        {...props}
      />
    </div>
  );
}

export default MainNavigationMobile;
