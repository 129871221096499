import { IncomingMessage } from 'http';
import { NextApiRequest } from 'next';
import { NextRequest } from 'next/server';
import { bool } from 'config/env';
import { Log } from 'services/log';

const getHeader = (
  req: NextRequest | IncomingMessage | undefined,
  name: Lowercase<string>,
): any => {
  let value: string | undefined = undefined;
  if (!req) return undefined;
  if (req?.headers?.get && typeof req.headers.get === 'function') {
    value = req.headers.get(name) as string;
  } else {
    value = req.headers[name];
  }
  return value;
};

export function getServerVars(req?: NextRequest | IncomingMessage) {
  if (typeof window !== 'undefined') {
    console.warn("don't call on client");
  }

  return {
    logLevel: process.env.LOGLEVEL_FRONTEND,
    userAgent: req?.headers['user-agent'] ?? '',
    isPreview: getHeader(req, 'x-is-preview') ?? process.env.IS_PREVIEW,
    isPrelive: bool(getHeader(req, 'x-is-prelive') ?? process.env.IS_PRELIVE),
    cdnUrl: '',
    gtmUrl: process.env.GTM_URL || '',
    baseUrl: getHeader(req, 'x-base-url') ?? process.env.BASE_URL,
    basicAuth:
      getHeader(req, 'x-authorization') ??
      (process.env.HAS_BASIC_AUTH ? process.env.BASIC_AUTH : false),
    hasBasicAuth: getHeader(req, 'x-has-basic-auth') ?? process.env.HAS_BASIC_AUTH,
    nodeEnv: 'production',
    port: '3000',
    theme: getHeader(req, 'x-theme-name') || process.env.THEME || 'DEFAULT',
    caasProjectDb: getHeader(req, 'x-caas-project-db') ?? process.env.CAAS_PROJECT_DB,
    isHeaderSources: true,
    appStage: process.env.APP_STAGE,
    campus: getCampusEnv(req),
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY ?? '',
  } as unknown as { [key: string]: string };
}

export function getCampusEnv(req?: NextApiRequest | IncomingMessage | NextRequest) {
  let campusCatalogId = String(
    getHeader(req, 'x-campus-catalog-id') || process.env.CAMPUS_CATALOG_ID!,
  );
  if (!/^\d+\/\d+\/\d+\/\d+$/.test(campusCatalogId)) {
    Log.warn(
      String.raw`campus catalog id must be according to the scheme "/^\d+\/\d+\/\d+\/\d+$/"`,
    );
  }
  if (!campusCatalogId.endsWith('/')) {
    campusCatalogId = campusCatalogId.concat('/');
  }

  return {
    CAMPUS_CATALOG_ID: campusCatalogId,
    CAMPUS_DASHBOARD_REPORT: process.env.CAMPUS_DASHBOARD_REPORT!,
    CAMPUS_CLASS_CAMPUS: process.env.CAMPUS_CLASS_CAMPUS!,
    CAMPUS_CLASS_COURSETYPES: process.env.CAMPUS_CLASS_COURSETYPES!,
    CAMPUS_CLASS_TARGETGROUPS: process.env.CAMPUS_CLASS_TARGETGROUPS!,
    CAMPUS_CLASS_COMP_KNOWLEDGE: process.env.CAMPUS_CLASS_COMP_KNOWLEDGE!,
    CAMPUS_CLASS_LEARNINGFORMS: process.env.CAMPUS_CLASS_LEARNINGFORMS!,
    CAMPUS_CLASS_LOCATIONS:
      getHeader(req, 'x-campus-class-locations') || process.env.CAMPUS_CLASS_LOCATIONS!,
    CAMPUS_BASE_URL: process.env.CAMPUS_BASE_URL!,
    CAMPUS_COSTS_INCLUDED: process.env.CAMPUS_COSTS_INCLUDED!,
    CAMPUS_META: {
      NAME: '10000',
      SUMMARY: '1104957',
      PLANNING_STATUS: '10072',
      DURATION_OF_USE: '10068',
      CATERING: '1007853',
      DESCRIPTION: '10001',
      HOTEL: '1007609',
      ENTERTAINMENT: '1007855',
      INFOBOX: '1105028',
      START_DATE: '10004',
      END_DATE: '10005',
      DURATION: '10018',
      LOCATION: '10097',
      MAX_CAPACITY: '10009',
      PRICE: '10033',
      IMAGE: '10012',
      LANGUAGE: '1139040',
      COURSE_CONTENTS: ['1139073', '1139075', '1139077', '1139079', '1139081', '1139083'],
      COURSE_BENEFITS: ['1139085', '1139087', '1139089', '1139091', '1139095', '1139097'],
      WEBINAR: '1140388',
      ELEARNING: '1140387',
      WEBCAST: '1140389',
      EVENT: '1140386',
      SEMINAR: '1140386',
      PODCAST: '896371',
      POPULAR: '1140353',
      TARGETGROUP: '1140295',
    },
    CAMPUS_FILTER_TYPE_BLACKLIST: [1140386, 1140388],
    SCORM_URI: process.env.APP_STAGE?.includes('prod')
      ? 'https://campus.prod.web.geberit.com/pages/direct-login.jsf'
      : 'https://campus.qa.web.geberit.com/pages/direct-login.jsf',
  };
}

export function getServerPageProps(req: NextRequest | IncomingMessage) {
  return {
    userAgent: req.headers['user-agent'],
    theme: getServerVars(req).theme,
  };
}
