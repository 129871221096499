import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import key from 'weak-key';
import debounce from 'lodash/debounce';
import { Badge, Container } from '@geberit/gdds';

// components
import { Translation } from 'components/Translation/Translation';
import { LanguageSelector } from '../language-switch/language-selector';
import { LocatorSelector } from '../locator-switch/locator-selector';
import { NavButton } from '../common/nav-button';
import { Initials } from '../profile/Initials';
import { ProfilePage } from '../profile/profile-page';
import { NavItemWrapper } from '../common/nav-item-wrapper';

// styles
import {
  NavItemContainer,
  OnlineCatalogButton,
  AnimationLayerContainer,
  Animation,
} from '../common/common.styles';
import { MetaNavContainer, MetaNavLinksContainer, ButtonContainer } from './meta-nav.styles';

// utils
import { navigationMetaItemsSelector } from 'utils/selectors/navigationSelectors';
import { useMetaNavigationInfo } from '../../../../utils/use-metanav';
import { ocbSelector, webshopLandingpageUrlSelector } from 'utils/selectors/globalsSelectors';
import currentLanguageSelector from 'utils/selectors/languageSelectors';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { useNavOpen } from '../mega-menu-provider';
import { siteShortcutTrackingAction } from '../../../../trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { shoppingBasketCountSelector } from 'utils/selectors/shoppingBasketSelector';

const initialAnimation: Animation = { animationType: 'higher', animationPhase: 1 };

export function MetaNav() {
  const metaNavigationItems = useSelector(navigationMetaItemsSelector);
  const currentLanguage = useSelector(currentLanguageSelector);
  const country = currentLanguage?.slice(currentLanguage.length - 2);
  const router = useRouter();
  const { isNavOpen, setIsNavOpen } = useNavOpen();
  const track = useTracking();

  const onlineCatalogButton = useSelector(ocbSelector);
  const {
    ciamEnabled,
    login,
    logout,
    loggedIn,
    orderHistoryUrl,
    webshopEnabled,
    webshopBasketPageUrl,
    profile,
    ciamProfileUrl,
  } = useMetaNavigationInfo();
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const ocbURL = useTranslationByKey(onlineCatalogButton?.linkTarget as string);
  const [animation, setAnimation] = useState<Animation>(initialAnimation);
  const shoppingCartCount = useSelector(shoppingBasketCountSelector);
  const webshopLandingPageUrl = useSelector(webshopLandingpageUrlSelector);
  const webShopUrl = shoppingCartCount === 0 ? webshopLandingPageUrl : webshopBasketPageUrl;

  const handleTrackedLogin = () => {
    track.trackEvent(siteShortcutTrackingAction('Login'));
    login(currentLanguage, `${country}_WebsitePlatform`);
  };

  const handleLogout = () => {
    router.push(ciamProfileUrl);
    setIsNavOpen(false);
  };

  useEffect(() => {
    const debouncedSetAnimation = debounce(() => {
      setAnimation({ animationPhase: 2, animationType: 'deeper' });
    }, 300);

    if (isNavOpen) {
      debouncedSetAnimation();
    } else {
      setAnimation(initialAnimation);
    }
  }, [isNavOpen]);

  return (
    <MetaNavContainer>
      <AnimationLayerContainer {...animation}>
        <Container maxContentWidth="80rem">
          <LocatorSelector />
          <ButtonContainer>
            {ciamEnabled && (
              <NavButton
                leftIconName={loggedIn ? undefined : 'profile'}
                paddingLeft={loggedIn}
                onClick={loggedIn ? handleLogout : handleTrackedLogin}
              >
                {loggedIn ? (
                  <>
                    <Initials
                      initials={`${profile?.firstName.charAt(0)}${profile?.lastName.charAt(0)}`}
                    />
                    {`${profile?.firstName} ${profile?.lastName}`}
                  </>
                ) : (
                  <Translation id="mega_menu_login" />
                )}
              </NavButton>
            )}
            {webshopEnabled && !webshopDowntime && webShopUrl && (
              <NavButton
                className="basket"
                leftIconName="onlineshop"
                onClick={() => {
                  setIsNavOpen(false);
                  router.push(webShopUrl);
                }}
              >
                <Translation id="mega_menu_shopping_cart" />
                {shoppingCartCount > 0 && (<span className="cart-count">
                  <Badge color="info" value={shoppingCartCount} size="big" />
                </span>)}
              </NavButton>
            )}
            {ciamEnabled && loggedIn && webshopEnabled && !webshopDowntime && (
              <NavButton
                leftIconName="catalogue"
                onClick={() => {
                  setIsNavOpen(false);
                  router.push(orderHistoryUrl);
                }}
              >
                <Translation id="web20_ciam_order_history" />
              </NavButton>
            )}

            {loggedIn && (
              <NavButton leftIconName="logout" onClick={logout}>
                <Translation id="web20_ciam_logout" />
              </NavButton>
            )}
          </ButtonContainer>

          {onlineCatalogButton?.visible && (
            <OnlineCatalogButton
              isInNavbar={false}
              stylingType="inverse"
              stretched={{
                xsmall: true,
                small: true,
                medium: true,
                large: true,
                xlarge: true,
              }}
              onClick={() => {
                track.trackEvent(siteShortcutTrackingAction('Online Catalog'));
                window.open(ocbURL, '_blank');
              }}
            >
              <Translation id={onlineCatalogButton.label} />
            </OnlineCatalogButton>
          )}

          <MetaNavLinksContainer>
            {metaNavigationItems?.map((metaNavItem) => {
              return (
                <NavItemContainer key={key(metaNavItem)}>
                  <NavItemWrapper item={metaNavItem} aria-label={metaNavItem?.ariaLabel} />
                </NavItemContainer>
              );
            })}
          </MetaNavLinksContainer>
        </Container>
        <LanguageSelector />
      </AnimationLayerContainer>
      <ProfilePage />
    </MetaNavContainer>
  );
}
