import produce from 'immer';
import _sortBy from 'lodash.sortby';
import _isEmpty from 'lodash.isempty';
import { VideoGalleryAction } from './actions';
import { Reducer } from 'redux';
import { VideoGalleryState } from './reducer.types';

const DEFAULT_STATE: VideoGalleryState = {
  channels: [],
  isFetching: false,
  isInitial: true,
  didInvalidate: false,
  lastFetched: null,
  error: null,
};

const videocenterReducer: Reducer<VideoGalleryState, VideoGalleryAction> = produce(
  (draft: VideoGalleryState, action: VideoGalleryAction) => {
    switch (action.type) {
      case 'UPDATE_CHANNEL':
        const currentChannel = Object.values(draft.channels).find(
          (element) => element.previewId === action.payload.previewId,
        );

        if (currentChannel) {
          Object.keys(currentChannel).forEach((key) => {
            if (action.payload[key]) {
              currentChannel[key] = action.payload[key];
            }
          });
        }

        break;

      case 'ADD_CHANNEL':
        if (!_isEmpty(action.payload)) {
          if (draft.channels.some((channel) => channel.previewId === action.payload.previewId))
            break;

          draft.channels = _sortBy([...draft.channels, action.payload], ['index', 'title']);
        }
        break;

      case 'DELETE_CHANNEL':
        draft.channels = draft.channels.filter(
          (channel) => channel.previewId !== action.payload.previewId,
        );
        break;
    }
  },
  DEFAULT_STATE,
);

export default videocenterReducer;
