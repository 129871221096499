/**
 * component which genereates HTML-comments used to turn search index off
 * not really a styled component, therefore using inline styles to hide
 * the comments in react workaround from breaking anything.
 */
export function SearchIndexOff({ children }: React.PropsWithChildren) {
  return (
    <>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: '<!-- searchindex:off -->' }}
        style={{ display: 'none' }}
      />
      {children}
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: '<!-- searchindex:on -->' }}
        style={{ display: 'none' }}
      />
    </>
  );
}
