import { trackTemplate } from '../../utils/tracking';

/**
 * Tab click
 * @param string text
 */
export function technicalError(errorCode, url) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Technical',
      action: errorCode,
      label: url,
      value: '0',
    },
  });
}
