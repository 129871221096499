// styles
import styles from './styles.scss';

// components
import { Translation } from '../Translation/Translation';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { siteShortcutTrackingAction } from 'features/header/trackingActions';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { useTracking as useTracking2 } from 'utils/tracking/track';

function OcbButton() {
  const track = useTracking();
  const linkTarget = useTranslationByKey('web20_ocb_link_target');

  const { trackClick } = useTracking2();

  function clickHandler() {
    track.trackEvent(siteShortcutTrackingAction('Online Catalog'));
    trackClick({ click_intent: 'catalog', click_element: 'button' });
  }

  return (
    <a
      onClick={clickHandler}
      className={`button button--white button--background-primary noArrow ocb ${styles.catalogButton}`}
      href={linkTarget}
      target="_blank"
      rel="noreferrer"
    >
      <span>
        <Translation id="web20_ocb_label" />
      </span>
    </a>
  );
}

export default OcbButton;
