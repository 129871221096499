// utils
import { currentPageSelector, currentPageIdSelector } from '../../utils/selectors/pageSelectors';
import { currentLanguageSelector } from '../../utils/selectors/languageSelectors';

// actions
import { updateFooter } from '../Footer/actions';
import { footerPreviewIdSelector } from '../../utils/selectors/footerNavigationSelectors';
import { navigationMetaPreviewIdSelector } from '../../utils/selectors/navigationSelectors';
import { headerLinksFulfilled } from '../Navigation/actions';
import { Dispatch } from 'redux';

/**
 * @return {Object}
 */
function doUpdateSectionOnPage(pageId: string, section: { content: any; previewId: string }) {
  return {
    type: 'page/UPDATE_SECTION_ON_PAGE' as const,
    pageId,
    previewId: section.previewId,
    content: section.content,
  };
}

/**
 * @return {Object}
 */
function doAddSectionToPage(pageId: string, newSection: any) {
  return {
    type: 'page/ADD_SECTION_TO_PAGE' as const,
    pageId,
    newSection,
  };
}

/**
 * @return {Object}
 */
function doDeleteSection(pageId: string, previewId: string) {
  return {
    type: 'page/DELETE_SECTION_ON_PAGE' as const,
    pageId,
    previewId,
  };
}

/**
 * @return {Object}
 */
export function doUpdatePage(pageId: string, page: any) {
  return {
    type: 'page/UPDATE_PAGE' as const,
    pageId,
    page,
    lastFetched: Date.now(),
  };
}

/**
 * @return {Object}
 */
function doUpdateImage(pageId: string, contentAreas: any) {
  return {
    type: 'page/UPDATE_IMAGE' as const,
    pageId,
    contentAreas,
  };
}

/**
 * @return {Object}
 */
export function updateSectionOnPage(updatedSection: any) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const pageId = currentPageIdSelector(state);
    const language = currentLanguageSelector(state);
    const footerId = footerPreviewIdSelector(state);
    const metaId = navigationMetaPreviewIdSelector(state);

    if (updatedSection.previewId === footerId) {
      dispatch(updateFooter(updatedSection));
    } else if (updatedSection.previewId === metaId) {
      dispatch(headerLinksFulfilled(updatedSection.content, language));
    } else {
      dispatch(doUpdateSectionOnPage(pageId, updatedSection));
    }
  };
}

/**
 * @return {Object}
 */
export function updatePage(updatedPage: any) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const pageId = currentPageIdSelector(state);
    let pageContent;

    if (Object.prototype.hasOwnProperty.call(updatedPage, 'content')) {
      pageContent = updatedPage.content;
    } else {
      pageContent = updatedPage;
    }

    dispatch(doUpdatePage(pageId, pageContent));
  };
}

/**
 * Loop through json to find imageobject
 *
 * @param {Array} haystack
 * @param {String} searchProperty
 * @param {String} searchValue
 * @param {Array} replaceKeys
 * @param {String} replaceWith
 */
function deepReplace(
  haystack: string | Record<string, Record<string, string> | string>,
  searchProperty: string,
  searchValue: string,
  replaceKeys: string[],
  replaceWith: string,
) {
  Object.keys(haystack).forEach((key) => {
    if (haystack[key] !== null && typeof haystack[key] === 'object') {
      deepReplace(haystack[key], searchProperty, searchValue, replaceKeys, replaceWith);
      return;
    }
    if (key === searchProperty && haystack[searchProperty] === searchValue) {
      replaceKeys.forEach((repl) => {
        if (haystack[repl]) {
          // eslint-disable-next-line
          haystack[repl] = replaceWith;
        }
      });
    }
  });
}

/**
 * @return {Object}
 */
export function updateImage(updatedImage) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const pageId = currentPageIdSelector(state);
    const page = currentPageSelector(state);
    const { contentAreas } = page;
    const timeStamp = new Date().getTime();
    const updatedImagePath = `${updatedImage.reference}?${timeStamp}`;

    const newContent = produce(contentAreas, (draft) => {
      deepReplace(
        draft,
        'previewId',
        updatedImage.previewId,
        ['url', 'url2x', 'url3x', 'picture'],
        updatedImagePath,
      );
    });

    dispatch(doUpdateImage(pageId, newContent));
  };
}

/**
 * @return {Object}
 */
export function addSectionToPage(newSection: any) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const pageId = currentPageIdSelector(state);

    dispatch(doAddSectionToPage(pageId, newSection));
  };
}

function doMoveSection(pageId: string, sectionPreviewId: string, direction: string) {
  return {
    type: 'page/MOVE_SECTION_ON_PAGE' as const,
    pageId,
    sectionPreviewId,
    direction,
    step: 1,
  };
}

/**
 * @return {Object}
 */
export function moveSection(sectionPreviewId: string, direction: string) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const pageId = currentPageIdSelector(state);
    dispatch(doMoveSection(pageId, sectionPreviewId, direction));
  };
}

/**
 * @return {Object}
 */
export function deleteSection(sectionPreviewId: string) {
  return (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const pageId = currentPageIdSelector(state);

    dispatch(doDeleteSection(pageId, sectionPreviewId));
  };
}

export type PageAction =
  | ReturnType<typeof doUpdateSectionOnPage>
  | ReturnType<typeof doAddSectionToPage>
  | ReturnType<typeof doDeleteSection>
  | ReturnType<typeof doUpdatePage>
  | ReturnType<typeof doUpdateImage>
  | ReturnType<typeof doMoveSection>;
