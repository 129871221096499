import { memoize } from 'proxy-memoize';
import { emptyStringFallback, isEmpty } from '../is-empty';
import { isPreviewSelector } from './environmentSelectors';
import currentLanguageSelector from './languageSelectors';

// Get the current page id
export const currentPageIdSelector = (state: AppState) => state.page.currentPageId;

// Get the current page
export const currentPageSelector = (state: AppState) => state.page[currentPageIdSelector(state)];

export const lastPagePreviewIdSelector = (state: AppState) => {
  if (!state.page.lastPageId) return null;
  if (!state.page[state.page.lastPageId]) return null;

  return state.page[state.page.lastPageId].previewId;
};

// Get the alternative languages
export const alternativePageLanguagesSelector = memoize((state: AppState) => {
  const currentPage = currentPageSelector(state);
  const isPreview = isPreviewSelector(state);
  if (!currentPage) return null;

  const { languages } = currentPage;
  if (isEmpty(languages)) return null;

  return isPreview ? languages : languages.filter((l) => l.hidden === false);
});

// Get current page type
export const currentPageTypeSelector = (state: AppState) => {
  const currentPage = currentPageSelector(state);

  if (!currentPage) return null;

  return emptyStringFallback(currentPage.type, null);
};

// Get current page language
export const currentPageLanguageSelector = (state: AppState) => {
  // get current page
  const currentPage = currentPageSelector(state);
  if (
    !currentPage ||
    typeof currentPage.languages === 'undefined' ||
    currentPage.languages.length < 1
  ) {
    return null;
  }

  // get current language
  const currentLanguage = currentLanguageSelector(state);
  if (!currentLanguage) return null;

  // filter current page languages by using the current language
  return currentPage.languages.filter((lang) => lang.lang === currentLanguage).pop() ?? null;
};

// Get current page type
export const currentPagePreviewIdSelector = (state: AppState) => {
  const currentPage = currentPageSelector(state);

  if (!currentPage) return null;

  return emptyStringFallback(currentPage.previewId, null);
};
