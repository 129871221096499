import * as React from 'react';
import { useRouter } from 'next/router';
import { Link, Button } from '@geberit/gdds';

// components
import { Translation } from 'components/Translation/Translation';

// utils
import { classNameBuilder as buildClassName } from 'utils/classNameBuilder';

// styles
import styles from './ciamlogin.scss';

interface CiamGddsLoginViewProps {
  opened: boolean;
  webshopEnabled: boolean;
  webshopDowntime: boolean;
  isMobile: boolean;
  isTablet: boolean;
  loggedIn: boolean;
  ciamProfileUrl: any;
  toHistory: any;
  logout: () => void;
  openLogin: () => void;
  openRegistration: () => void;
  toggleCiamFlyout: (opened: boolean) => void;
  ciamDiv: React.RefObject<HTMLDivElement>;
}

function CiamGddsLoginView({
  isMobile,
  isTablet,
  opened,
  openLogin,
  ciamProfileUrl,
  toHistory,
  logout,
  ciamDiv,
  openRegistration,
  toggleCiamFlyout,
  webshopEnabled = false,
  webshopDowntime = false,
  loggedIn = false,
}: Readonly<CiamGddsLoginViewProps>) {
  const [offsetRight, setOffsetRight] = React.useState();
  const router = useRouter();

  const getIconOffset = () => {
    const icon = document.querySelector('button.ciam-icon');
    const offsetRight = icon?.getBoundingClientRect().right;

    setOffsetRight(document.body.clientWidth - offsetRight);
  };

  React.useEffect(() => {
    if (!isMobile && !isTablet) {
      window.addEventListener('resize', getIconOffset);

      return () => {
        window.removeEventListener('resize', getIconOffset);
      };
    }
  }, []);

  React.useEffect(() => {
    if (!isMobile && !isTablet && opened && !offsetRight) {
      getIconOffset();
    }
  }, [opened]);

  return (
    <div
      className={buildClassName(opened && styles.opened, styles.ciamGddsOverlay)}
      {...(offsetRight > 0 ? { style: { right: offsetRight } } : {})}
      ref={ciamDiv}
    >
      {loggedIn ? (
        <>
          <div className={styles.loggedInButtons}>
            {webshopEnabled && !webshopDowntime && (
              <Button
                stylingType="flat"
                symbol="Catalogue"
                onClick={() => {
                  router.push(toHistory.target);
                  toggleCiamFlyout(false);
                }}
              >
                <Translation id="web20_ciam_order_history" />
              </Button>
            )}
            <Button
              stylingType="flat"
              symbol="Profile"
              onClick={() => {
                router.push(ciamProfileUrl);
                toggleCiamFlyout(false);
              }}
            >
              <Translation id="web20_ciam_geberit_profile" />
            </Button>
          </div>
          <Button
            stylingType="primary"
            symbol="LogOut"
            onClick={() => {
              logout();
              toggleCiamFlyout(false);
            }}
          >
            <Translation id="web20_ciam_logout" />
          </Button>
        </>
      ) : (
        <>
          <Button stylingType="primary" onClick={openLogin}>
            <Translation id="web20_ciam_login" />
          </Button>
          <div className={styles.register}>
            <span>
              <Translation id="web20_ciam_nociam" />
            </span>
            <Link stylingType="flat" onClick={openRegistration}>
              <Translation id="web20_ciam_registration" />
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default CiamGddsLoginView;
