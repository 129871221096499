import { useState, useRef, useEffect } from 'react';
import striptags from 'striptags';
import isEmpty from 'lodash.isempty';
import Downshift from 'downshift';

// styles
import styles from './searchautocomplete.module.scss';

// components
import SearchBarInput from './SearchBarInput';

/**
 * state reducer to check if blur or mouseup event is happening, in that case
 * do not blur the input as per default
 * @param {*} state
 * @param {*} changes
 */
function downshiftStateReducer(state, changes) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.blurInput:
      return { ...state, isOpen: state.isOpen };
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.unknown:
      if (!isEmpty(changes.inputValue)) {
        return { ...state, inputValue: striptags(changes.inputValue), isOpen: false };
      }
      return { ...state, isOpen: false };
    default:
      return changes;
  }
}

interface SearchAutocompleteProps {
  id: string;
  handleSubmit: (query: string) => void;
  fetchData: (query: string) => void;
  closeSearchOverlay?: null | (() => void);
  className?: string;
  searchPlaceholder?: string;
  showSearch?: boolean;
  hasAnimation?: boolean;
  initialQuery?: string;
  searchInputRef?: null | React.RefObject<HTMLInputElement>;
}

function SearchAutocomplete({
  id,
  initialQuery = '',
  handleSubmit,
  className = '',
  searchPlaceholder = '',
  hasAnimation = false,
  closeSearchOverlay = null,
  showSearch = false,
  searchInputRef = null,
}: Readonly<SearchAutocompleteProps>) {
  const [items, setItems] = useState([]);
  const [initialValue, setInitialValue] = useState(initialQuery);
  const cancelToken: React.RefObject<any> = useRef(null);

  const onHandleSubmit = (e) => {
    handleSubmit(e);
    setItems([]);
  };

  useEffect(() => {
    setInitialValue(initialQuery);
  }, [initialQuery]);

  useEffect(
    () => () => {
      if (cancelToken.current) {
        cancelToken.current();
      }
    },
    [],
  );

  return (
    <Downshift
      id={id}
      inputValue={initialValue}
      stateReducer={downshiftStateReducer}
      onInputValueChange={(value) => setInitialValue(value)}
    >
      {({
        getItemProps,
        getInputProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        isOpen,
        inputValue,
      }) => (
        <div className={styles.downshiftWrapper}>
          <label aria-label={searchPlaceholder} {...getLabelProps()} />
          <SearchBarInput
            getInputProps={getInputProps}
            handleSubmit={onHandleSubmit}
            value={inputValue}
            className={className}
            searchPlaceholder={searchPlaceholder}
            hasAnimation={hasAnimation}
            closeSearchOverlay={closeSearchOverlay}
            setItems={setItems}
            showSearch={showSearch}
            searchInputRef={searchInputRef}
          />
          {/* Menu (always rendered for accessibility reasons) */}
          {!isOpen || !striptags(initialQuery).length >= 3 || items.length === 0 ? null : (
            <div className="c-suggestion-flyout">
              <div className="grid-container grid-x" {...getMenuProps()}>
                {!isEmpty(items) &&
                  items.map((item, index) => (
                    <div
                      className={`cell ${hasAnimation ? 'small-11 medium-11 large-8' : 'small-12'}`}
                      key={item}
                      {...getItemProps({
                        item,
                        onClick: () => onHandleSubmit(item),
                        value: item.replace(
                          inputValue.toLowerCase(),
                          `<em>${inputValue.toLowerCase()}</em>`,
                        ),
                        'data-active': highlightedIndex === index,
                      })}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: item.replace(
                          inputValue.toLowerCase(),
                          `<em>${inputValue.toLowerCase()}</em>`,
                        ),
                      }}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
}

export default SearchAutocomplete;
