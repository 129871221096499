import { bool } from 'config/env';
import { getServerVars } from 'utils/get-server-vars';
import getRawBody from 'raw-body';
import { Log } from './log';

export type IServiceName =
  | 'navigationservice'
  | 'keyvalueservice'
  | 'technicalservice'
  | 'contentservice'
  | 'productservice'
  | 'maintenanceservice'
  | 'eventservice'
  | 'jobservice'
  | 'insightsservice'
  | 'elasticsearchservice'
  | 'campusservice'
  | 'videoservice'
  | 'schemaservice'
  | 'downloadsservice'
  | 'formservice'
  | 'mediareleaseservice';

export function Service(name: IServiceName, req: any) {
  if (typeof window !== 'undefined') {
    console.error("Dont' call services in the browser");
  }

  let appStage = 'dev';
  if (process.env.APP_STAGE?.startsWith('prod')) {
    appStage = 'prod';
  } else if (process.env.APP_STAGE?.startsWith('qa')) {
    appStage = 'qa';
  }

  const [url, filterHeaders] = (function () {
    const { isPrelive, isPreview } = getServerVars(req);
    if (name === 'jobservice') {
      return [`https://jobs.prod.platform.web.geberit.com`, () => false];
    }
    if (name === 'elasticsearchservice') {
      return [
        `https://api-${
          isPrelive ? 'prelive-' : ''
        }elasticsearchservice.${appStage}.platform.web.geberit.com`,
        (header) => header === 'x-geb-api-req',
      ];
    }

    const previewPrefix = bool(isPreview) ? '-preview' : '';
    const prelivePrefix = isPrelive ? '-prelive' : previewPrefix;

    return [
      process.env.IS_LOCAL === '1'
        ? `https://api${prelivePrefix}-${name}.${appStage}.platform.web.geberit.com`
        : `http://${name}:8080`,
      () => true,
    ];
  })();

  const getValue = (value: string) => {
    if (req?.headers?.get && typeof req.headers.get === 'function') {
      return req.headers.get(value);
    }
    return req?.headers[value];
  };
  let headers: Record<string, string> = {
    'caas-database': getValue('x-caas-project-db'),
  };

  if (process.env.IS_LOCAL === '1') {
    headers['x-geb-api-req'] = '1';
    if (process.env.CAAS_PROJECT_DB) {
      headers['caas-database'] = process.env.CAAS_PROJECT_DB;
    }
  }
  headers = Object.keys(headers)
    .filter(filterHeaders)
    .reduce((acc, key) => {
      acc[key] = headers[key];
      return acc;
    }, {});

  const raw = async (endpoint: string) => {
    if (
      name === 'productservice' &&
      (endpoint.includes('customer/') || endpoint.includes('cart/session'))
    ) {
      // proxy complete request with authorization
      headers['uidsignature'] = getValue('uidsignature');
      headers['signaturetimestamp'] = getValue('signaturetimestamp');
    }

    const requestInit: RequestInit = {
      method: req?.method,
      headers,
      redirect: 'manual',
    };

    const withBody = ['PATCH', 'POST', 'PUT', 'DELETE'].includes(req?.method);

    if (withBody) {
      const contentType = getValue('content-type') || 'application/json';
      const body = await getRawBody(req).catch((ex) => {
        if (contentType.includes('application/json')) {
          return JSON.stringify(req.body);
        } else if (contentType.includes('application/x-www-form-urlencoded')) {
          return Object.entries(req.body)
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&');
        }
        return req.body;
      });
      requestInit.body = body;
      requestInit.headers = {
        ...requestInit.headers,
        'content-type': contentType,
      };
    }

    if (name === 'formservice') {
      requestInit.headers = {
        ...requestInit.headers,
        referer: getValue('referer'),
        uploadToBlobStorage: getValue('uploadtoblobstorage') || 'false',
      };
    }
    if (name === 'elasticsearchservice') {
      requestInit.headers = {
        ...requestInit.headers,
        'x-geb-api-req': '1',
      };
    }

    const proxyResp = await fetch(`${url}${endpoint}`, requestInit).catch((e) => {
      return {
        ok: false,
        headers: new Map(),
        text: async () => e.toString(),
        arrayBuffer: async () => new ArrayBuffer(0),
        status: 500,
      };
    });
    Log.info(
      `Service[${name}]\n --- ${requestInit.method}\n`,
      `URL:     ${url}${endpoint}\n`,
      `HEADERS: ${JSON.stringify(requestInit.headers)}\n`,
      `STATUS:  ${proxyResp.status}\n`,
      '---',
    );

    return proxyResp;
  };
  const json = async (endpoint: string) => {
    const proxyResp = await raw(endpoint);

    if (!isOk(proxyResp)) {
      const message = await proxyResp.text();
      Log.error(`Service[${name}] http-status ${proxyResp.status}`, message);
      throw {
        status: proxyResp.status,
        message,
        service: name,
        pathname: endpoint,
      };
    }

    if (proxyResp.headers.get('content-length') !== '0') {
      return proxyResp.json();
    }

    return {};
  };

  return { raw, json };
}

function isOk(
  resp:
    | Response
    | {
        ok: boolean;
        text: () => Promise<string>;
        status?: number;
      },
): resp is Response {
  return resp.ok;
}
