import produce from 'immer';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { Reducer } from 'redux';

import { PageAction } from './actions';
import VideoGalleryReducer from '../VideoGallery/reducer';
import { VideoGalleryState } from '../VideoGallery/reducer.types';

type PageState = AppState['page'];

/**
 * @param {Object} state – current state
 * @param {Object} action – action
 *
 * @returns {Object} new state
 */
const reducer: Reducer<PageState, PageAction> = produce((draft: PageState, action: PageAction) => {
  if ('page' in action && draft[action.pageId] === undefined) {
    draft[action.pageId] = {};
  }

  switch (action.type) {
    case 'page/UPDATE_IMAGE':
      const areasForUpdateImg = draft[action.pageId].contentAreas ?? {};
      for (let area in areasForUpdateImg) {
        areasForUpdateImg[area].forEach((element, i) => {
          if (!isEqual(element, action.contentAreas[area][i])) {
            areasForUpdateImg[area][i] = action.contentAreas[area][i];
          }
        });
      }
      break;

    case 'page/DELETE_SECTION_ON_PAGE':
      const areasForDeleteSection = draft[action.pageId].contentAreas ?? {};
      for (let area in areasForDeleteSection) {
        areasForDeleteSection[area] = areasForDeleteSection[area].filter(
          (i) => i.previewId !== action.previewId,
        );
      }
      break;

    case 'page/UPDATE_SECTION_ON_PAGE':
      const areasForUpdateSection = draft[action.pageId]?.contentAreas ?? {};
      for (let area in areasForUpdateSection) {
        areasForUpdateSection[area].forEach((element, i) => {
          if (element.previewId === action.previewId) {
            areasForUpdateSection[area][i] = action.content;
          }
        });
      }
      break;

    case 'page/MOVE_SECTION_ON_PAGE':
      const areasForMoveSection = draft[action.pageId].contentAreas ?? {};

      Object.keys(areasForMoveSection).forEach((areaName) => {
        let positionToInsert;
        let currentPosition;
        const sections = areasForMoveSection[areaName];
        sections.forEach((section, index) => {
          if (section.previewId === action.sectionPreviewId) {
            currentPosition = index;
          }
        });

        if (action.direction === 'DOWN') {
          positionToInsert = currentPosition + action.step;
        } else {
          positionToInsert = currentPosition - action.step;
        }
        sections.splice(positionToInsert, 0, sections.splice(currentPosition, 1)[0]);
      });
      break;

    case 'page/ADD_SECTION_TO_PAGE':
      const areasForAddSection = draft[action.pageId].contentAreas ?? {};

      const key = Object.hasOwnProperty.call(
        draft[action.pageId].contentAreas,
        action.newSection.context.body,
      )
        ? action.newSection.context.body
        : 'landingpage_content';
      const content = areasForAddSection[key];
      const ref = isEmpty(content) ? [] : content;

      areasForAddSection[key] = ref.concat({
        ...action.newSection.content,
      });
      break;
    case 'page/UPDATE_PAGE':
      draft[action.pageId].lastFetched = action.lastFetched;
      Object.keys(action.page).forEach((pageKey) => {
        draft[action.pageId][pageKey] = action.page[pageKey];
      });
      break;

    default: {
      if (
        draft[draft.currentPageId]?.type?.includes('videocenter') &&
        draft[draft.currentPageId]?.initialData
      )
        draft[draft.currentPageId].initialData = VideoGalleryReducer(
          { channels: draft[draft.currentPageId].initialData } as VideoGalleryState,
          action,
        ).channels;
    }
  }
}, {});

export default reducer;
