import { useEffect, useReducer, useRef, useState, createContext, useMemo } from 'react';
import produce from 'immer';
import { useSelector } from 'react-redux';

// utils
import {
  webshopEnabledSelector,
  campusDashboardUrlSelector,
  campusReferenceUrlSelector,
} from 'utils/selectors/globalsSelectors';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { addInterceptor, addToAllInterceptorMethods } from 'utils/interceptors';
import { mergeCart } from 'components/ShoppingBasket/actions';
import { CookieStorage } from './CookieStorage';
import { addSeconds } from 'date-fns';
import { useGetEndpoint } from 'utils/hooks/use-endpoint';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';

export const SET_SESSION = 'SET_SESSION';
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const SET_CAMPUS_COURSE_TOKEN = 'SET_CAMPUS_COURSE_TOKEN';
const SET_CAMPUS_TOKEN = 'SET_CAMPUS_TOKEN';
const SET_CIAM = 'SET_CIAM';
const SET_CAMPUS_TOKEN_LOGOUT = 'SET_CAMPUS_TOKEN_LOGOUT';

interface State {
  session: string | null;
  sessionIsLoading: boolean;
  ciam: {
    UID: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    salutation: string;
    country: string;
    city: string;
    zip: string;
  } | null;
  campus: {
    token: string | null;
    refreshToken: string | null;
    loading: boolean;
    courses: {
      [key: string]: {
        token: string;
        refreshToken: string;
      };
    };
  };
  gigyaWindow?: any;
}

const initialState = {
  session: null,
  sessionIsLoading: false,
  ciam: null,
  campus: {
    token: null,
    refreshToken: null,
    loading: true,
    courses: {},
  },
};

export const SessionContext = createContext<{
  state: State;
  dispatch: React.Dispatch<any>;
  redirectUrl: { current?: string };
}>({ state: initialState, dispatch: () => null, redirectUrl: {} });

const sessionReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_SESSION:
      draft.session = action.session;
      break;

    case SET_SESSION_LOADING:
      draft.sessionIsLoading = action.isLoading;
      break;

    case SET_CIAM:
      draft.ciam = action.ciam;
      break;

    case SET_CAMPUS_COURSE_TOKEN:
      if (draft.campus.courses[action.courseId]) {
        draft.campus.courses[action.courseId] = {
          ...draft.campus.courses[action.courseId],
          ...action.data,
        };
      } else {
        draft.campus.courses[action.courseId] = action.data;
      }

      break;

    case SET_CAMPUS_TOKEN:
      draft.campus.token = action.token;
      if (action.refreshToken) {
        draft.campus.refreshToken = action.refreshToken;
      }
      if (typeof action.loading !== 'undefined') {
        draft.campus.loading = action.loading;
      }
      break;

    case SET_CAMPUS_TOKEN_LOGOUT:
      draft.campus.token = null;
      draft.campus.refreshToken = null;
      draft.campus.courses = {};
      break;

    default:
  }

  draft.campus.token = CookieStorage.getItem('campus_access_token');
  draft.campus.refreshToken = CookieStorage.getItem('campus_refresh_token');
}, initialState);

/**
 * Add Ciam header to webshop requests
 */
let headersTimeout: ReturnType<typeof setTimeout> | null = null;

export async function addCiamHeader(gigyaWindow) {
  if (!headersTimeout && gigyaWindow) {
    addInterceptor(
      'ciam-signature',
      (config) =>
        new Promise((resolve) => {
          if (config.url.includes('/webshop/')) {
            const setHeader = ({ UIDSignature, signatureTimestamp }) => {
              config = addToAllInterceptorMethods(config, 'UIDSignature', UIDSignature);
              config = addToAllInterceptorMethods(config, 'signatureTimestamp', signatureTimestamp);

              resolve(config);
            };
            gigyaWindow.socialize.getUserInfo({
              format: 'jsonp',
              callback: setHeader,
              signIDs: true,
            });
          } else {
            resolve(config);
          }
        }),
    );

    headersTimeout = setTimeout(() => {
      addCiamHeader(gigyaWindow);
    }, 2000);
  }
}

const setStorageToken = (gigyaWindow) =>
  new Promise<void>((resolve) => {
    gigyaWindow.accounts.getJWT({
      callback: async ({ id_token: idToken, requestParams: { sessionExpiration }, ...rest }) => {
        await addCiamHeader(gigyaWindow);
        CookieStorage.setItem('ciam_jwt', idToken, addSeconds(new Date(), sessionExpiration));
        window.localStorage.setItem('_ciamtoken', idToken);
        resolve();
      },
    });
  });

export const initSamlWorkflow = (href: string, lang: string) => {
  let redirectURL = href;
  if (!href.startsWith('http')) {
    const divider = href.startsWith('/') ? '' : '/';
    redirectURL = `${window.location.origin}${divider}${href}`;
  }

  window.location.href = `/api/campus/saml/login/?l=${lang}&mode=initSSO&spName=campus&redirectURL=${encodeURIComponent(
    redirectURL,
  )}`;
};

const removeCiamLocalStorage = () => {
  window.localStorage.removeItem('_ciamtoken');
  CookieStorage.removeItem('ciam_jwt');
};

export function SessionProvider({ children }: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(sessionReducer, initialState);
  const redirectUrl = useRef<string | undefined>();
  const value = useMemo(() => ({ state, dispatch, redirectUrl }), [state, dispatch]);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const campusDashboardUrl = useSelector(campusDashboardUrlSelector);
  const campusReferenceUrl = useSelector(campusReferenceUrlSelector);
  const lang = useCurrentLanguage();
  const { getEndpoint } = useGetEndpoint();
  const [gigyaWindow, setGigyaWindow] = useState(undefined);
  useEffect(() => {
    const storageToken = window.localStorage.getItem('gigyaAccessToken');
    const storageRefresh = window.localStorage.getItem('gigyaRefreshToken');
    dispatch({
      type: SET_CAMPUS_TOKEN,
      token: storageToken,
      refreshToken: storageRefresh,
      loading: false,
    });
    window.addEventListener('gigya.session.ready', () => {
      setGigyaWindow(window);
    });
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined' && window?.gigya) {
      window.gigya.accounts.addEventHandlers({
        onLogin: async (props) => {
          const { profile: user, data, UID } = props;
          const { relationship, salutation } = data || {};
          await addCiamHeader(window.gigya);
          dispatch({
            type: SET_CIAM,
            ciam: { ...user, role: relationship, salutation, UID },
          });
          dispatch({ type: SET_SESSION_LOADING, isLoading: false });

          await setStorageToken(window.gigya);

          // hide login screen for geberit employess after login
          window.gigya.accounts.hideScreenSet({
            screenSet: 'Geberit-RegistrationLogin',
            startScreen: 'geberit-login-nosc',
          });

          const session = window.localStorage.getItem('gsessid');

          // only campus pages can handle accessToken and refreshToken callbacks
          // so we only need to init the saml workflow if the user starts the login from
          // a campus site
          if (campusDashboardUrl.startsWith(window.location.pathname)) {
            initSamlWorkflow(redirectUrl.current || campusDashboardUrl, lang);
            redirectUrl.current = undefined;
          }

          if (props.context.country && session && webshopEnabled && !webshopDowntime) {
            await addCiamHeader(window.gigya);
            await mergeCart(getEndpoint, session, UID, props.context.country);
          }
        },
        onLogout: async () => {
          removeCiamLocalStorage();
          await addCiamHeader(null);
          dispatch({ type: SET_CIAM, ciam: null });
          dispatch({ type: SET_SESSION_LOADING, isLoading: false });
          dispatch({ type: SET_CAMPUS_TOKEN_LOGOUT });

          if (CookieStorage.getItem('campus_refresh_token')) {
            const redirectURL = window.location.pathname.startsWith(campusReferenceUrl)
              ? campusReferenceUrl
              : window.location.pathname;
            window.location.href = `/api/campus/saml/logout?redirectURL=${encodeURIComponent(
              redirectURL,
            )}`;
          }
        },
      });

      const tokenJWT = window.localStorage.getItem('_ciamtoken');

      if (tokenJWT !== null) {
        addCiamHeader(window.gigya);
        window.gigya.accounts.getAccountInfo({
          callback: async ({ profile: user, data, UID, status }) => {
            if (status === 'FAIL') {
              await addCiamHeader(null);
              dispatch({ type: SET_CIAM, ciam: null });
              removeCiamLocalStorage();
              dispatch({ type: SET_SESSION_LOADING, isLoading: false });
            } else {
              const { relationship, salutation } = data || {};
              await addCiamHeader(window.gigya);
              dispatch({
                type: SET_CIAM,
                ciam: { ...user, role: relationship, salutation, UID },
              });

              setTimeout(() => {
                dispatch({ type: SET_SESSION_LOADING, isLoading: false });
              }, 200);
            }
          },
        });
      } else {
        dispatch({ type: SET_SESSION_LOADING, isLoading: false });
      }
    }

    return () => {
      addCiamHeader(null);
    };
  }, [gigyaWindow]);

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>;
}
