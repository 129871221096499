import { useState, useEffect } from 'react';

export function isInViewport(element, offset = 0) {
  if (!element) return false;
  const { height, top } = element.getBoundingClientRect();
  return top + height + offset >= 0 && top - offset <= window.innerHeight;
}

export function useEnteredViewport({ ref, offset, continueObserve = false }) {
  const [enteredViewport, setEnteredViewport] = useState(false);

  useEffect(() => {
    if (!enteredViewport || continueObserve) {
      const checkIsInViewport = () => {
        if (isInViewport(ref?.current, offset)) {
          if (!enteredViewport) {
            setEnteredViewport(true);
          }
        } else if (enteredViewport) {
          setEnteredViewport(false);
        }
      };

      window.addEventListener('scroll', checkIsInViewport);
      window.addEventListener('resize', checkIsInViewport);
      checkIsInViewport();

      return () => {
        window.removeEventListener('scroll', checkIsInViewport);
        window.removeEventListener('resize', checkIsInViewport);
      };
    }

    return () => {};
  }, [ref, offset, enteredViewport, continueObserve]);

  return enteredViewport;
}

export function useLazyLoad(ref, src) {
  const enteredViewport = useEnteredViewport({ ref, offset: 50 });
  const [sourceLoaded, setSourceLoaded] = useState(null);

  useEffect(() => {
    setSourceLoaded(src);
  }, [enteredViewport, src]);

  return sourceLoaded;
}
