import { useRef, useEffect, useContext } from 'react';
import { MetaNavigationDesktop } from '@geberit/gdds';

// components
import { SearchIndexOff } from 'components/SearchIndex/SearchIndexOff';
import NavigationRestriction from 'components/Navigation/NavigationRestriction';
import MainNavigation from 'components/Navigation/MainNavigation/MainNavigation';
import GddsMainNavigation from 'components/Navigation/MainNavigation/gdds-main-navigation';
import { AnimatedLogo } from 'components/Logo/animated-logo';

// hooks
import useTouchDetector from 'utils/hooks/use-touch-detector';
import useStickyHeader from '../../utils/use-sticky-header';
import useIsCampus from '../../utils/use-is-campus';
import { useThemeName } from 'utils/hooks/use-theme';
import { LanguageSwitchProps, MetaNavNordicsDataProps, useMetaNavNordicsData } from '../../utils';
import { useKolo } from 'utils/hooks/use-kolo';
import { useTwyford } from 'utils/hooks/use-twyford';
import { useIsDesktop } from 'components/App/SizeProvider';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useClientSideRender } from 'utils/hooks/use-client-side-render';

// styles
import styles from './header.module.scss';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';
import { buildSize, gridSizes, themeToGridSize } from 'utils/gridSize';
import { NavigationContext, NAVIGATION_OPEN } from 'components/Navigation/NavigationContext';

function NordicsHeader() {
  const headerRef = useRef<HTMLDivElement>(null);
  const isTouch = useTouchDetector();
  const isCampus = useIsCampus();
  const themeName = useThemeName();
  const isKolo = useKolo();
  const isTwyford = useTwyford();
  const isDesktop = useIsDesktop();
  const isClient = useClientSideRender();
  const currentLanguage = useCurrentLanguage();

  const {
    state: { flyoutState },
  } = useContext(NavigationContext) || {};

  const { isSticky, stickyClass, expandedClass } = useStickyHeader(headerRef);
  const _metanaviData = useMetaNavNordicsData(isDesktop, isTwyford);
  const languageSwitch = makeValueFieldUnique({ currentLanguage, metanaviData: _metanaviData });
  const metanaviData = isTwyford ? { ..._metanaviData, languageSwitch } : _metanaviData;

  useEffect(() => {
    if (flyoutState === NAVIGATION_OPEN) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'inherit';
    }
  }, [flyoutState]);

  return (
    <SearchIndexOff>
      <div
        id="page-header"
        className={classNameBuilder(
          styles.pageHeader,
          isTouch && styles.isTouch,
          isTouch && 'isTouch',
          stickyClass,
          expandedClass,
          'pageHeader--meta-nav',
          isTwyford && 'twyford',
          isKolo && 'kolo',
          styles.nord,
          'nord',
        )}
        ref={headerRef}
      >
        <div className={styles.mobile} ref={headerRef}>
          {isDesktop || <GddsMainNavigation />}
        </div>
        <div className={styles.desktop}>
          <div className={styles.gddsMetaNavi}>
            <MetaNavigationDesktop
              maxContainerWidth={buildSize(gridSizes[themeToGridSize[themeName]])}
              {...metanaviData}
              icons={metanaviData?.secondIcons?.filter?.(Boolean)}
            />
          </div>
          <MainNavigation isSticky={isSticky} isCampus={isCampus} isDesktop={isDesktop} />
        </div>
        {isClient && <AnimatedLogo siblingRef={headerRef} />}
      </div>
      <NavigationRestriction />
    </SearchIndexOff>
  );
}

function makeValueFieldUnique({
  currentLanguage,
  metanaviData,
}: {
  currentLanguage: string;
  metanaviData: MetaNavNordicsDataProps | null;
}): LanguageSwitchProps | undefined {
  if (!metanaviData) return undefined;

  if (!metanaviData?.languageSwitch?.languages) {
    return undefined;
  }

  return {
    currentLanguage,
    languages: metanaviData.languageSwitch.languages,
    onClick: (content) => {
      const item = metanaviData?.languageSwitch?.languages?.find(
        (lang) => lang.text === content.content.text,
      );
      return metanaviData?.languageSwitch?.onClick({ content: item });
    },
  };
}

export default NordicsHeader;
