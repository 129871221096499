import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

// styles
import { LogoLinkWrapper } from './Logo.styles';

// types
import type { LogoProps } from './types';

// components
import { Image } from '../Image/Image';
import { Translation } from '../Translation/Translation';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { logoTrackingAction } from './trackingActions';
import { useNord } from 'utils/hooks/use-nord';
import { logoSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationByKey } from 'utils/hooks/use-translations';

const Logo = forwardRef(({ homepage, onClick }: Readonly<LogoProps>, ref) => {
  const customLogo = useSelector(logoSelector);
  const track = useTracking();
  const isNord = useNord();
  const logoTooltip = useTranslationByKey('web20_logo_tooltip');
  const nordicsLogo = customLogo?.logoRef ?? '/images/nordics_tmp.svg';

  const logo = (
    <>
      <Image
        className="image"
        src={isNord ? nordicsLogo : '/images/geberit_logo.svg'}
        alt={logoTooltip}
        title={logoTooltip}
        width={undefined}
        height={isNord ? 40 : undefined}
        staticImage
      />
      {!isNord && (
        <span className="claim">
          <Translation id="web20_logo_claim_short" />
        </span>
      )}
    </>
  );

  return homepage ? (
    <LogoLinkWrapper
      ref={ref}
      tabIndex={0}
      href={homepage.url}
      id="logo"
      onClick={(e) => {
        track.trackEvent(logoTrackingAction('Logo'));
        onClick?.(e);
      }}
    >
      {logo}
    </LogoLinkWrapper>
  ) : (
    <LogoLinkWrapper
      ref={ref}
      tabIndex={0}
      href="/"
      id="logo"
      onClick={(e) => {
        track.trackEvent(logoTrackingAction('Logo'));
        onClick?.(e);
      }}
    >
      {logo}
    </LogoLinkWrapper>
  );
});

export default Logo;
