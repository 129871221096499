// components
import { NavItem } from './nav-item';

// styles
import { NavItemContainer } from './nav-item.styles';

// utils
import { useDesktopFlyoutOpen } from 'mm/mega-menu-provider';
import { PathNode } from 'mm/hooks/use-navigation-items';
import { navigationTrackingAction } from 'components/Navigation/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { useReactKeys } from 'mm/language-switch/use-react-keys';
import { decodingContent } from 'utils/decodingContent';
import { useTracking as useTracking2 } from 'utils/tracking/track';

type ThirdLevelNavigationItemsProps = {
  pathNode?: PathNode;
  navItemRefs?: React.MutableRefObject<any[]>;
  withPictogram?: boolean;
};

export function ThirdLevelNavigationItems({
  pathNode,
  withPictogram,
}: Readonly<ThirdLevelNavigationItemsProps>) {
  const { setIsDesktopFlyoutOpen, isDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const track = useTracking();

  const processedChildren = pathNode?.children;
  const childrenWithKeys = useReactKeys(processedChildren ?? [], ['ariaLabel', 'label', 'url']);

  const { trackClick } = useTracking2();

  return (
    <>
      {childrenWithKeys.map((navItem) => {
        const isSelected = isNavItemSelected(pathNode!, navItem.originalIndex);
        const hasNoChildren = !navItem.children?.length;
        const notClickable = isSelected && hasNoChildren;

        return (
          <NavItemContainer key={navItem.key}>
            <NavItem
              shallow={!navItem.url}
              href={navItem?.url || '/'}
              aria-label={navItem?.ariaLabel}
              selected={isSelected}
              onClick={getNavItemClickHandler(
                { ...navItem, depth: pathNode!.depth + 1 },
                navItem.originalIndex,
                navItem.url,
              )}
              tabIndex={isDesktopFlyoutOpen ? 0 : -1}
              notClickable={notClickable}
              pictogram={navItem.image}
              withPictogram={withPictogram}
            >
              {decodingContent(navItem.label ?? '')}
            </NavItem>
          </NavItemContainer>
        );
      })}
    </>
  );

  function isNavItemSelected(pathNode: PathNode, index: number): boolean | undefined {
    // in desktop, navigation stands only for the first level,
    // so index === navigation?.selectedIndex means that the element is selected
    // secondLevelNavigation & thirdLevelNavigation is for second/third level
    return index === pathNode?.selectedIndex;
  }

  function getNavItemClickHandler(pathNode: PathNode, index: number, url: string) {
    return () => {
      setIsDesktopFlyoutOpen(false);
      if (url) {
        track.trackEvent(navigationTrackingAction(pathNode.label));
        trackClick({
          click_intent: 'navigation',
          click_text: pathNode.label,
          click_element: `navigation level ${pathNode.depth}`,
        });
      }
    };
  }
}
