import { useSelector } from 'react-redux';

// utils
import { baseURLSelector } from './selectors/globalsSelectors';
import { isEmpty } from './is-empty';

export function SchemaOrganization({
  country,
  customerServicePhone,
  technicalSupportPhone,
  billingSupportPhone,
  salesPhone,
  socialLinks,
  searchUrl,
  searchQuery,
  image,
}) {
  const baseURL = useSelector(baseURLSelector);
  const jsonTemplate = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: country,
    url: baseURL,
    logo: image || `/images/geberit_logo.svg`,
    potentialAction: {
      '@type': 'SearchAction',
      target: `baseURL${searchUrl}?q=${searchQuery || ''}`,
      'query-input': 'required name=search_term_string',
    },
  };

  // check if phone number is set and is not an empty translation
  const isEmptyPhoneNumber = (phone) => !isEmpty(phone) && !phone.includes('web20');

  /**
   * Create contact point entry
   *
   * @param phone
   * @param type
   * @returns {{'@type': string, telephone: *, contactType: *}}
   */
  const contactPointEntry = (phone, type) => ({
    '@type': 'ContactPoint',
    telephone: phone,
    contactType: type,
  });

  /**
   * Render regarding contactpoint if number is not empty
   *
   * @param phone
   * @param name ContactPoint name
   */
  const renderEntry = (phone, name) => {
    if (isEmptyPhoneNumber(phone)) {
      return contactPointEntry(phone, name);
    }

    return null;
  };

  /**
   *  Create ContactPoint
   *
   * @returns {(boolean|{'@type', telephone, contactType})[]}
   */
  const contactPoint = () => {
    const array = [];
    array.push(renderEntry(customerServicePhone, 'customer service'));
    array.push(renderEntry(technicalSupportPhone, 'technical support'));
    array.push(renderEntry(billingSupportPhone, 'billing support'));
    array.push(renderEntry(salesPhone, 'sales'));
    return array.filter((entry) => !isEmpty(entry));
  };

  // return facebook and google+ url
  const getSpecificLinks = () =>
    socialLinks.map((link) => link.url).filter((link) => !isEmpty(link));

  let schema = jsonTemplate;

  // only add contactPoint to json if a number is given
  if (
    isEmptyPhoneNumber(customerServicePhone) ||
    isEmptyPhoneNumber(technicalSupportPhone) ||
    isEmptyPhoneNumber(billingSupportPhone) ||
    isEmptyPhoneNumber(salesPhone)
  ) {
    schema = {
      ...schema,
      contactPoint: contactPoint(),
    };
  }

  // add sameAs for all given social links
  if (!isEmpty(socialLinks) && !isEmpty(getSpecificLinks())) {
    schema = {
      ...schema,
      sameAs: getSpecificLinks(),
    };
  }

  return schema;
}
