import styled, { css } from 'styled-components';
import Link from 'next/link';

// utils
import { media } from '../utils/no-touch-device';

const spanStyles = ({ selected, isOption }) => css`
  span {
    min-height: unset;

    &.first-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 0.75rem;

      ${media.noTouch`
        padding: 0.625rem;
      `}

      svg {
        width: 1.25rem;
        visibility: ${(function () {
          if (isOption) {
            if (selected) {
              return 'visible';
            }
            return 'hidden';
          }

          return 'visible';
        })()};
        fill: currentColor;
      }
    }

    &.first-icon {
      left: 0;
      top: 0;
    }
  }
`;

export const StyledLink = styled(Link)<{
  $iconPosition: 'left' | false;
  highlighted: boolean;
  selected?: boolean;
  isOption?: boolean;
}>`
  ${({ theme, selected, $iconPosition, isOption, highlighted }) => {
    return css`
      position: relative;
      width: 100%;
      height: fit-content;
      min-height: 2.75rem;
      display: flex;
      align-items: center;
      font-size: ${theme.fontSizes[2]}px;
      line-height: ${theme.lineHeights[1]}px;
      font-weight: ${theme.fontWeights.medium};
      border: none;
      outline-offset: 0;
      transition: none;
      hyphens: none;
      padding: 0.75rem 2.75rem 0.75rem 0.5rem;
      ${$iconPosition === 'left' && 'padding-left: 2.75rem'};

      && {
        color: ${selected ? theme.colors.primaryLight : theme.colors.black};
        border-radius: 0px;
      }

      &.disabled {
        color: rgba(0, 0, 0, 0.26);
        pointer-events: none;
      }

      ${media.noTouch`
        min-height: 2.5rem;
        padding: 0.5rem 2.5rem 0.5rem 0.5rem;
        ${$iconPosition === 'left' && 'padding-left: 2.5rem;'};
      `}

      &:before {
        content: none;
      }

      ${highlighted &&
      `
          background-color: rgba(${theme.colors.transparentBlack}, 0.08);
        `}

      border: 1px solid transparent;

      &&:focus-visible {
        border: 1px solid ${theme.colors.primaryLight};
        outline: 1px solid ${theme.colors.primaryLight};
        text-decoration: none;
      }

      &&:visited:focus {
        text-decoration: none;
      }

      &:focus,
      &:link {
        text-decoration: none;
      }

      ${media.hover`
        text-decoration: none;
        background-color: rgba(${theme.colors.transparentBlack}, 0.08);
      `}

      &:active {
        ${({ theme }) => theme.global.disableTapHighlight}
        text-decoration: none;
        background-color: ${theme.colors.cl15};
        color: ${theme.colors.white};
      }

      ${spanStyles({ selected, isOption })}
    `;
  }}
`;
