import { bool } from 'config/env';

export function isPreviewSelector(state: AppState) {
  return bool(state?.globals?.server?.isPreview);
}

export function isCCSelector(state: AppState) {
  const isPreview = isPreviewSelector(state);
  const hasWindow = typeof window !== 'undefined';
  return isPreview && hasWindow && window.location !== window.parent.location;
}
