import { useEffect, useMemo } from 'react';
import App, { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { Scripts } from 'scenes/Scripts';
import { UAParser } from 'ua-parser-js';

import { SizeProvider } from '../components/App/SizeProvider';
import '../styles/global.scss';
import { Service } from '../services';
import createStore from '../store';
import { Layout } from '../components/Layout';
import { SessionProvider } from '../components/Session/SessionContext';
import { createInitialStore } from 'services/store';
import { AppInsights } from '../components/app-insights/app-insights';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { THEMES, THEME_NAMES } from '../themes';
import { PreviewListener } from 'components/ContentCreator/PreviewListener';
import { isClient } from '../utils/environment';
import { getServerVars } from 'utils/get-server-vars';
import { doUpdatePage } from 'components/Page/actions';
import { useBrowserPlugin } from '../features/browser-plugin/browser-plugin';
import { Log } from '../services/log';
import { useScrollToSection } from 'utils/hooks/use-scroll-to-section';
import { UsercentricsScripts } from '../scenes/UsercentricsScripts';

type IInitialProps = NonNullable<Awaited<ReturnType<typeof MyApp.getInitialProps>>>;

const queryClient = new QueryClient();

function MyApp({
  Component,
  pageProps,
  initialStore: fallbackStore,
  deviceType,
  logLevel,
}: AppProps & IInitialProps): JSX.Element {
  const { pageId, initialStore = fallbackStore, page: pageFromServer } = pageProps;

  const hasHistorySection = pageFromServer?.contentAreas?.content?.some(
    (contentItem) => contentItem.type === 'history',
  );

  useEffect(() => {
    window._logLevel = logLevel;
  }, []);

  const store = useMemo(() => {
    if (!initialStore) return null;
    const { store: reduxStore } = createStore('', {
      ...initialStore,
      translations: Object.entries(initialStore.translations).reduce((a, c) => {
        a[c[0]] = {
          keys: Object.entries(c[1]).reduce((acc, t) => {
            acc[t[0]] = t[1].value;
            return acc;
          }, {}),
          keysWithPreviewId: c[1],
        };
        return a;
      }, {}),
      page: {
        [pageId]: pageFromServer,
        currentPageId: pageId,
      },
    });
    return reduxStore;
  }, [initialStore, pageId, pageFromServer]);

  useBrowserPlugin(store);
  useScrollToSection(hasHistorySection);

  useEffect(() => {
    if (window.TPP_SNAP && pageFromServer?.type === 'new_page_from_tpp') {
      window.TPP_SNAP.renderElement(pageFromServer.previewId).then((pageFromTPP) => {
        store?.dispatch(doUpdatePage(pageId, pageFromTPP));
        const root = document.querySelector<HTMLDivElement>('#__next');
        if (pageFromTPP.pageRefPreviewId && root) {
          root.dataset.previewId = pageFromTPP.pageRefPreviewId;
          window.TPP_SNAP.setPreviewElement(pageFromTPP.pageRefPreviewId);
        }
      });
    }
  }, [pageFromServer?.type, pageId, store]);

  const { theme } = initialStore.globals;
  const isMobile = deviceType === 'mobile';
  const isTablet = deviceType === 'tablet';

  let fallbackWidth = theme === THEME_NAMES.DEFAULT ? 992 : 1024;
  if (isTablet) fallbackWidth = 768;
  if (isMobile) fallbackWidth = 360;

  const globals = initialStore.globals[initialStore.homepageList.currentLanguage];

  const server = initialStore.globals.server?.theme
    ? initialStore.globals.server
    : { ...(initialStore.globals.server || {}), theme: theme };

  return (
    <ErrorBoundary
      isNord={theme === THEME_NAMES.NORD}
      isKolo={theme === THEME_NAMES.KOLO}
      isTwyford={theme === THEME_NAMES.TWYFORD}
    >
      <UsercentricsScripts globals={globals} server={server} pageUrl={pageProps?.page?.url} />
      <Scripts globals={globals} server={server} />
      <SizeProvider fallbackWidth={fallbackWidth}>
        <ThemeProvider key="geberit/theme-provider" theme={THEMES[theme]}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <PreviewListener isPreview>
                <SessionProvider>
                  <AppInsights>
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  </AppInsights>
                </SessionProvider>
              </PreviewListener>
            </Provider>
          </QueryClientProvider>
        </ThemeProvider>
      </SizeProvider>
    </ErrorBoundary>
  );
}

MyApp.getInitialProps = async (context: AppContext) => {
  const {
    ctx: { req },
  } = context;

  const appProps = await App.getInitialProps(context);
  const parsedUA = new UAParser(req?.headers['user-agent']);
  const deviceType = parsedUA?.getDevice().type;
  // for error pages we need to query the initial store data here, because `getServerSideProps` is not available
  // on _error pages and for technical pages
  if (context.ctx.pathname === '/500' || appProps.pageProps.statusCode === 500) {
    let theme: string | undefined;
    if (isClient()) {
      theme = await fetch('/api/500/')
        .then((r) => r.json())
        .then((d) => d.theme);
    } else {
      theme = getServerVars(req).theme;
    }
    return {
      ...appProps,
      initialStore: {
        homepageList: { currentLanguage: 'en', item: [] },
        translations: {},
        globals: {
          theme,
        },
      },
    };
  } else if (
    context.ctx.pathname === '/404' ||
    (context.ctx.pathname.startsWith('/_') && context.ctx.pathname !== '/_translations')
  ) {
    if (isClient()) {
      const data = await fetch('/api/404/').then((response) => response.json());
      return { ...appProps, initialStore: data, deviceType };
    }

    const { homepages } = await Service('navigationservice', req).json('/navigation/homepagelist');
    const fallbackLang = homepages.find((i) => i.isMasterLanguage)?.language;
    const initialStore = await createInitialStore(homepages, fallbackLang, req);
    return {
      ...appProps,
      initialStore,
      deviceType,
    };
  }
  return { ...appProps, deviceType, logLevel: process.env.LOGLEVEL_FRONTEND };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  Log.debug(metric);
}

export default MyApp;
