import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import key from 'weak-key';

// components
import CiamLogin from 'components/Navigation/MetaNavigation/ciam-login';

// selectors & actions
import {
  ciamEnabledSelector,
  ciamProfileUrlSelector,
  groupDataSelector,
  orderHistoryUrlSelector,
  webshopBasketPageUrlSelector,
  webshopEnabledSelector,
} from 'utils/selectors/globalsSelectors';
import { shoppingBasketCountSelector } from 'utils/selectors/shoppingBasketSelector';
import { siteShortcutTrackingAction } from 'components/Navigation/trackingActions';

// utils
import { useCiamLogin } from 'utils/hooks/useCiam';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { handlePopup } from 'utils/openInPopup';

export function asExternalLink(links) {
  if (links.target.includes('https://') || links.target.includes('http://')) {
    if (links.window === '_popup') {
      const windowId = key(links);
      handlePopup(links, windowId);
    } else {
      window.open(links.target, links.window);
    }
  }
}

export const cleanLanguageValue = (lang) => {
  if (lang?.match(/[-|_]/)) {
    return lang?.split(/[-|_]/)[0];
  }
  return lang;
};

export const onClickGroupDlc = (router, track, dlcConfig: { url: string; target: string }) => {
  track.trackEvent(siteShortcutTrackingAction('Download Center'));
  if (dlcConfig.target === '_self') {
    router.push(dlcConfig.url);
  } else window.push(dlcConfig.url, '_blank');
};

export function useCiamFlyout() {
  const ciamEnabled = useSelector(ciamEnabledSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const toggleFlyout = () => setFlyoutOpen((p) => !p);
  const renderFlyout = useCallback(() => {
    if (!ciamEnabled) return null;

    return (
      <CiamLogin
        opened={flyoutOpen}
        toggleCiamFlyout={toggleFlyout}
        webshopEnabled={webshopEnabled}
      />
    );
  }, [ciamEnabled, webshopEnabled, flyoutOpen]);

  return { renderFlyout, flyoutOpen, toggleFlyout };
}

export function useMetaNavigationInfo() {
  const gigyaWindow = typeof window !== 'undefined' ? (window as any).gigya : null;
  const { loggedIn, login, logout, profile } = useCiamLogin(gigyaWindow); // todo: fix error: is second parameter mandatory?

  const shoppingCartCount = useSelector(shoppingBasketCountSelector);
  const orderHistoryUrl = useSelector(orderHistoryUrlSelector);
  const ciamEnabled = useSelector(ciamEnabledSelector);
  const ciamProfileUrl = useSelector(ciamProfileUrlSelector);
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopBasketPageUrl = useSelector(webshopBasketPageUrlSelector);
  const groupData = useSelector(groupDataSelector);

  return {
    shoppingCartCount,
    orderHistoryUrl,
    ciamEnabled,
    ciamProfileUrl,
    webshopEnabled,
    webshopBasketPageUrl,
    groupData,
    loggedIn,
    login,
    logout,
    profile,
  };
}

export function useMetaTranslations() {
  const translate = useTranslationFunction();
  const loginTranslation = translate('web20_ciam_login');
  const logoutTranslation = translate('web20_ciam_logout');
  const orderHistoryTranslation = translate('web20_ciam_order_history');
  const shoppingCartTranslation = translate('web20_metanavigation_shoppingcart');

  return {
    loginTranslation,
    logoutTranslation,
    orderHistoryTranslation,
    shoppingCartTranslation,
  };
}
