import { useCallback, useEffect, useRef, useState } from 'react';

// styles
import { LogoWrapper } from './Logo.styles';

// types
import type { Props, AnimatedLogoProps, LogoStateProps } from './types';

// utils
import { useGroup } from 'utils/hooks/use-group';
import { useSelector } from 'react-redux';
import { useIsDesktop, useIsMobile, useIsTablet } from '../App/SizeProvider';
import { useTracking as useTracking2 } from 'utils/tracking/track';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useSearchOpen } from '../../features/header/components/header/mega-menu/mega-menu-provider';
import { globalsSelector, showMegaMenuSelector } from 'utils/selectors/globalsSelectors';
import useTouchDetector from 'utils/hooks/use-touch-detector';

export function AnimatedLogo({
  siblingRef,
  shadowDisabled = false,
  sticky = false,
  className,
}: Readonly<Props>) {
  const animatedLogo: AnimatedLogoProps = useSelector(globalsSelector)?.['animatedLogos'];
  const showMegaMenu = useSelector(showMegaMenuSelector);
  const { isSearchOpen } = useSearchOpen();
  const lang = useCurrentLanguage();
  const [logo, setLogo] = useState<LogoStateProps>({
    src: undefined,
    altText: '',
  });
  const [top, setTop] = useState(0);
  const isGroup = useGroup();
  const isEnabled = animatedLogo?.enableAnimatedLogos === 'enabled';
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isTouch = useTouchDetector();
  const isTabletMegaMenu = useIsTablet({ gdds: true });
  const isMobile = useIsMobile();
  const logoRef = useRef<HTMLAnchorElement | null>(null);
  const { trackClick } = useTracking2();

  const getParent = useCallback(
    (header) => {
      if (isGroup) {
        if (isTouch === undefined) {
          return null;
        }
        if (isDesktop && !isTouch) {
          return header.children[1].children[0].children[0].children[0].children[0].children[0];
        }
      }
      if (showMegaMenu?.enabled) {
        if (isTabletMegaMenu) {
          return header.children[0];
        }
        return header.children[0].children[0].children[0];
      }

      return header.querySelector('[class^="main-navigation_mainNavigation"]');
    },
    [isDesktop, isGroup, isTabletMegaMenu, showMegaMenu?.enabled, isTouch],
  );

  const positionLogo = useCallback(() => {
    if (siblingRef) {
      setTop(
        () =>
          (siblingRef?.current?.querySelector('nav')?.firstChild as HTMLDivElement)?.offsetHeight,
      );
    }

    if (showMegaMenu?.enabled && (isTabletMegaMenu || isMobile)) {
      setTop(
        (document.querySelector('#mega-menu')?.children[0].children[0] as HTMLDivElement)
          .offsetHeight,
      );
    }

    const currentLogo = logoRef.current;

    if (currentLogo) {
      const header = showMegaMenu?.enabled
        ? (document.querySelector('#mega-menu') as HTMLDivElement)
        : (document.querySelector('#page-header') as HTMLDivElement);

      const parent = getParent(header);

      if (parent && !isSearchOpen) {
        const parentDimensions = parent.getBoundingClientRect();
        currentLogo.style.right = `${
          isTablet || isMobile || (showMegaMenu?.enabled && isTabletMegaMenu)
            ? 0
            : (!sticky && parentDimensions.left) + (showMegaMenu?.enabled && 24)
        }px`;
      } else if (isSearchOpen) {
        currentLogo.style.right = '';
      }

      if (isDesktop) {
        setLogo({ src: animatedLogo?.animatedLogoLarge, altText: animatedLogo?.altTextLarge });
      }

      if (isTablet || (showMegaMenu?.enabled && isTabletMegaMenu)) {
        setLogo({ src: animatedLogo?.animatedLogoMedium, altText: animatedLogo?.altTextMedium });
      }

      if (isMobile) {
        setLogo({ src: animatedLogo?.animatedLogoSmall, altText: animatedLogo?.altTextSmall });
      }
    }
  }, [
    siblingRef,
    showMegaMenu?.enabled,
    isTabletMegaMenu,
    isMobile,
    isDesktop,
    isTablet,
    animatedLogo,
    getParent,
    sticky,
    isSearchOpen,
  ]);

  useEffect(() => {
    positionLogo();

    window.addEventListener('resize', positionLogo);

    return () => {
      window.removeEventListener('resize', positionLogo);
    };
  }, [isDesktop, isTablet, isMobile, lang, positionLogo]);

  return isEnabled && typeof window !== 'undefined' ? (
    <LogoWrapper
      className={className}
      href={animatedLogo?.linkTarget}
      ref={logoRef}
      target="_blank"
      top={top}
      onClick={() => {trackClick({click_intent: "150yr logo", click_element:"150yr logo"})}}
      isSearchOpen={isSearchOpen}
      sticky={sticky}
    >
      <img src={logo.src} alt={logo.altText} />
      {!shadowDisabled && <div className="shadow"></div>}
    </LogoWrapper>
  ) : null;
}
