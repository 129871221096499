import key from 'weak-key';
import { Link, LinkProps } from '@geberit/gdds';

// utils
import { ContentProps } from './ContentProps';
import { popUpLink, internalMediumLink } from './trackingActions';
import { classNameBuilder } from 'utils/classNameBuilder';
import { decodingContent } from 'utils/decodingContent';
import { useTracking } from 'utils/hooks/useTracking';
import { useGdds } from 'utils/hooks/use-gdds';
import { useNord } from 'utils/hooks/use-nord';
import { handlePopup } from 'utils/openInPopup';
import { isEmpty } from 'utils/is-empty';

interface PopUpLinkProps {
  content: ContentProps;
  className?: string;
  onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: any;
  tracking?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  standardFontSize?: boolean;
  stylingType?: LinkProps['stylingType'];
  alignByContent?: LinkProps['alignByContent'];
  inContext?: boolean;
}

export function PopUpLink({
  content,
  content: { showArrow, localMedium, target, targeturl },
  className,
  onClick,
  tracking,
  children,
  stylingType,
  standardFontSize = true,
  inContext,
  alignByContent,
}: Readonly<PopUpLinkProps>) {
  const isGdds = useGdds();
  const isNord = useNord();
  const track = useTracking();
  const windowId = key(content);
  const linkText = isEmpty(children) ? decodingContent(content.text) : children;

  // if it has a specific tracking use it else take the default tracking for popups
  const handleTracking = (e) => {
    const link = e.currentTarget.href;
    if (tracking) {
      tracking(e);
    } else if (localMedium) {
      track.trackEvent(internalMediumLink(link));
    } else {
      track.trackEvent(popUpLink(targeturl || target || link));
    }
  };

  const handlePopupLink = (e) => {
    e.preventDefault();

    if (typeof onClick === 'function') {
      onClick();
    }

    handleTracking(e);
    handlePopup(content, windowId);
  };

  // generate classNames
  let classes;
  if (!className?.includes('arrow') || !className?.includes('noArrow')) {
    classes = `${className} ${showArrow ? 'arrow' : 'noArrow'}`;
  }
  const stylingChange = isNord && !showArrow ? 'primary' : 'secondary';

  if (isGdds) {
    return (
      <Link
        stylingType={stylingType || stylingChange}
        text={linkText}
        hasArrow={showArrow}
        target={content.target}
        standardFontSize={standardFontSize}
        /* eslint-disable-next-line no-script-url */
        onClick={content.target.includes('javascript:') ? handleTracking : handlePopupLink}
        windowTarget=""
        inContext={inContext}
        className={classNameBuilder(className, isNord && 'isNordicsStyle')}
        alignByContent={alignByContent}
      />
    );
  }

  /* eslint-disable-next-line no-script-url */
  if (content.target.includes('javascript:')) {
    return (
      <a
        href={content.target}
        className={className}
        onClick={handleTracking}
        aria-label={children ? undefined : content.text || undefined}
      >
        {linkText}
      </a>
    );
  }

  return (
    <a href={content.target} onClick={handlePopupLink} className={classes}>
      {linkText}
    </a>
  );
}
