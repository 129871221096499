import { useEffect } from 'react';

export function useScrollToSection(hasHistorySection: boolean) {
  useEffect(() => {
    if (hasHistorySection) return;
    setTimeout(() => {
      const hash = window.location.hash;
      if (hash) {
        let id = hash.slice(1);
        if ((hash.match(/#/g) || []).length > 1) {
          const hashes = decodeURIComponent(hash.split('#')?.[1]);
          const splittedHash = hashes?.split('|');
          id = splittedHash?.[splittedHash.length - 1];
        }
        const anchorSection = document.getElementById(id);
        if (anchorSection) {
          anchorSection.scrollIntoView({ block: 'start' });
        }
      }
    }, 750);
  }, []);
}
