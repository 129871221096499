import { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Button as GDDSButton } from '@geberit/gdds';
import { useYext } from '../../Search/use-yext';

// components
import { BUTTON_TYPES, BUTTON_COLORS, Button } from '../../Button/Button';
import Overlay from './Overlay';

// selectors
import {
  globalsSearchDisabled,
  globalsSearchSelector,
} from '../../../utils/selectors/globalsSelectors';
import { translationByKeySelector } from '../../../utils/selectors/translationSelectors';

// actions
import { NavigationContext, NAVIGATION_SEARCH_OPEN, NAVIGATION_OPEN } from '../NavigationContext';
import { useTracking } from '../../../utils/hooks/useTracking';
import { searchTrackingAction } from '../trackingActions';
import { useGdds } from '../../../utils/hooks/use-gdds';

interface SearchProps {
  stickyHeaderShown?: boolean;
  isMobile?: boolean;
}

function Search({ stickyHeaderShown = false, isMobile = false }: Readonly<SearchProps>) {
  const { state, dispatch } = useContext(NavigationContext) || {};
  const searchIconMobile = isMobile && state && state.searchOpen ? 'close' : 'search';
  const searchInputRef = useRef<HTMLInputElement>(null);
  const track = useTracking();
  const yextUrl = useYext();
  const isGdds = useGdds();
  const searchDisabled = useSelector(globalsSearchDisabled);
  const searchConfig = useSelector(globalsSearchSelector);
  const searchOpenButtonLabel = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_search_open_label'),
  );
  const searchCloseButtonLabel = useSelector((appState: AppState) =>
    translationByKeySelector(appState, 'web20_search_close_label'),
  );

  useEffect(() => {
    if (stickyHeaderShown && dispatch) {
      dispatch({ type: NAVIGATION_SEARCH_OPEN, open: false });
    }
  }, [stickyHeaderShown, dispatch]);

  if (searchDisabled || !searchConfig || !dispatch) return null;

  /**
   * Toggle search overlay
   */
  const toggleSearch = () => {
    track.trackEvent(searchTrackingAction('Search'));

    if (yextUrl) {
      window.location.href = yextUrl;
      return;
    }

    if (!state.searchOpen && state.flyoutState === NAVIGATION_OPEN) {
      document.body.classList.remove('mobilenav-open');
    }

    dispatch({ type: NAVIGATION_SEARCH_OPEN, open: !state.searchOpen });
    if (searchInputRef.current && !state.searchOpen) {
      searchInputRef.current.focus();
    }
  };

  return (
    <div className="c-search">
      {isGdds ? (
        <GDDSButton
          symbol="Search"
          stylingType="icon"
          isIcon
          height={{ xsmall: 3 }}
          onClick={toggleSearch}
          aria-label={state?.searchOpen ? searchCloseButtonLabel : searchOpenButtonLabel}
        />
      ) : (
        <Button
          symbol={searchIconMobile}
          color={BUTTON_COLORS.PRIMARY}
          type={BUTTON_TYPES.PLAIN}
          onClick={toggleSearch}
          aria-label={state?.searchOpen ? searchCloseButtonLabel : searchOpenButtonLabel}
        />
      )}
      <Overlay />
    </div>
  );
}

export default Search;
