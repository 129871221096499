import isEmpty from 'lodash.isempty';
import { currentLanguageSelector } from './languageSelectors';

// Get the homepage list
export const homepageListSelector = (state: AppState) => state.homepageList;

// Get the homepage
export function getHomepage(language, homepageList) {
  const list = homepageList.item || homepageList;

  if (isEmpty(language)) {
    return list.find((homepage) => homepage.isMasterLanguage) || list[0];
  }
  // take care of case insensitive
  return list.find((homepage) => homepage.language.toLowerCase() === language.toLowerCase());
}

export const homepageSelector = (state: AppState) => {
  const homepageList = homepageListSelector(state);
  const currentLanguage = currentLanguageSelector(state);

  if (!homepageList) return null;
  if (!homepageList.item) return null;
  return getHomepage(currentLanguage, homepageList);
};
