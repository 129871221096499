import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@geberit/gdds';

// styles
import styles from './styles.scss';

// components
import { Translation } from 'components/Translation/Translation';
import CampusLayout from '../Campus/CampusLayout';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { technicalError } from './trackingActions';
import { homepageSelector } from 'utils/selectors/homepageListSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

function CampusError() {
  const translate = useTranslationFunction();
  const homepage = useSelector(homepageSelector);
  const track = useTracking();

  useEffect(() => {
    track.trackEvent(technicalError(500, window.location.href));
  }, [track]);

  return (
    <CampusLayout noErrorBoundary>
      <div className="grid-container">
        <div className={styles.errorPageWrapper}>
          <h1 className="h2">
            <Translation id="campus_error_page_headline" />
          </h1>
          <span className={styles.campusContent}>
            <Translation id="campus_error_page_content" />
          </span>
          <Link
            stylingType="secondary"
            hasArrow
            target={homepage?.url}
            windowTarget="_self"
            text={translate('campus_error_page_link')}
          />
        </div>
      </div>
    </CampusLayout>
  );
}

export default CampusError;
