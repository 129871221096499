import React, { HTMLProps, PropsWithChildren } from 'react';
import { Icon } from '@geberit/gdds';
import type { UrlObject } from 'url';
import { StyledLink } from './nav-item.styles';
import { classNameBuilder } from 'utils/classNameBuilder';

type NavItemProps = PropsWithChildren<{
  href: UrlObject | string;
  isOption?: boolean;
  selected?: boolean;
  shallow?: boolean;
  leftIconUrl?: string | false;
  leftIconName?: string | false;
  rightIconName?: string | false;
  hasPictogram?: boolean;
  withPictogram?: boolean;
  firstLevel?: boolean;
  thirdLevel?: boolean;
  isOverviewItem?: boolean;
  notClickable?: boolean;
  highlighted?: boolean;
}> &
  HTMLProps<HTMLAnchorElement>;

export function NavItem({
  selected,
  leftIconName,
  shallow,
  children,
  isOption,
  highlighted,
  as,
  notClickable,
  disabled,
  ...anchorProps
}: NavItemProps) {
  return (
    <StyledLink
      notClickable={notClickable}
      shallow={shallow}
      isOption={isOption}
      className={classNameBuilder(selected && 'selected', disabled && 'disabled')}
      selected={selected}
      highlighted={highlighted}
      $iconPosition={leftIconName && 'left'}
      {...anchorProps}
    >
      {leftIconName && <Icon className="first-icon" symbol={leftIconName} />}
      {children}
    </StyledLink>
  );
}
