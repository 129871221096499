// components
import { ThirdLevelNavigationItems } from './third-level-navigation-items';

// styles
import { AnimationLayerContainer } from 'mm/common/common.styles';
import { StyledTypo } from 'mm/locator-switch/locator-selector.styles';
import { DesktopMainNavContainer } from '../common.styles';
import { ThirdLevelNavDesktop, TitleWrapper } from './third-level-navigation.styles';

import { useNavigation } from 'mm/mainnav/navigation-provider';
import { useDesktopFlyoutOpen } from 'mm/mega-menu-provider';

export function ThirdLevelNavigation() {
  const { thirdLevelNavigation } = useNavigation();
  const { isDesktopFlyoutOpen } = useDesktopFlyoutOpen();

  const desktopAnimationPhase = isDesktopFlyoutOpen ? 2 : 1;
  const desktopAnimationType = isDesktopFlyoutOpen ? 'higher' : 'deeper';

  if (!thirdLevelNavigation) {
    return null;
  }

  return (
    <ThirdLevelNavDesktop>
      <AnimationLayerContainer
        className="withDesktopAnimation"
        animationPhase={desktopAnimationPhase}
        animationType={desktopAnimationType}
        withDelay={isDesktopFlyoutOpen}
      >
        <TitleWrapper>
          <StyledTypo variant="p2">{thirdLevelNavigation?.label}</StyledTypo>
        </TitleWrapper>
        <DesktopMainNavContainer
          className={thirdLevelNavigation?.withPictogram ? 'pictogram' : undefined}
        >
          <ThirdLevelNavigationItems
            pathNode={thirdLevelNavigation}
            withPictogram={thirdLevelNavigation?.withPictogram}
          />
        </DesktopMainNavContainer>
      </AnimationLayerContainer>
    </ThirdLevelNavDesktop>
  );
}
