import produce from 'immer';
import { Reducer } from 'redux';
import { ThemeNameProps } from '../../themes';

const initialState = {};

type GlobalAction =
  | {
      type: 'globals/SET_SERVER_VARS';
      params: never;
    }
  | {
      type: 'globals/SET_DATEFORMAT';
      language: string;
      dateformat: string;
    }
  | {
      type: 'globals/SET_THEME';
      theme: ThemeNameProps;
    };

const reducer: Reducer<AppState['globals'], GlobalAction> = produce(
  (draft: AppState['globals'], action: GlobalAction) => {
    if ('language' in action && draft[action.language] === undefined) {
      draft[action.language] = {};
    }

    switch (action.type) {
      case 'globals/SET_SERVER_VARS':
        draft.server = action.params;
        break;

      case 'globals/SET_DATEFORMAT':
        draft[action.language].dateformat = action.dateformat;
        break;

      case 'globals/SET_THEME':
        draft.theme = action.theme;
        break;

      default:
    }
  },
  initialState,
);

export default reducer;
