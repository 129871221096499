import { bool, url } from './env';

const endpoints = {
  schema: {
    path: `/api/schema`,
  },
  content: {
    path: `/api/content`,
  },
  keyValue: {
    path: `/api/value`,
  },
  models: {
    path: `/api/models`,
  },
  shortUrls: {
    path: `/api/shorturls`,
  },
  events: {
    path: `/api/events`,
  },
  navigation: {
    path: `/api/navigation`,
  },
  technical: {
    path: `/api/technical`,
  },
  downloads: {
    path: `/api/downloads`,
  },
  search: {
    path: '/api/search',
  },
  suggest: {
    path: '/api/suggest',
  },
  form: {
    path: `/api/form`,
  },
  video: {
    path: `/api/video`,
  },
  webshop: {
    path: '/api/webshop',
  },
  catalog: {
    path: `/api/catalog`,
  },
  categories: {
    path: `/api/categories`,
  },
  productpdf: {
    path: '/api/productpdf',
  },
  maintenance: {
    path: `/api/info`,
  },
  campusToken: {
    path: `/api/campus/token`,
  },
  campusRefresh: {
    path: `/api/campus/refresh/`,
  },
  campusCourses: {
    path: `/api/campus/course-list/`,
  },
  campusCourseParticipants: {
    path: `/api/campus/course-participants/`,
  },
  campusTopics: {
    path: `/api/campus/topics/`,
  },
  campusCourseDetails: {
    path: `/api/campus/course-details`,
  },
  campusBookingState: {
    path: `/api/campus/booking-state/`,
  },
  campusBooking: {
    path: `/api/campus/book/`,
  },
  campusBookedCourseDetails: {
    path: '/api/campus/booked-course-details/',
  },
  campusSolr: {
    path: '/api/campus/catalog-list/',
  },
  campusRest: {
    path: '/api/campus/admin',
  },
  campusAttributes: {
    path: `/api/campus/attributes/`,
  },
  insights: {
    path: '/api/insights',
  },
  mediaReleases: {
    path: '/api/mediareleases',
  },
  newsDesk: {
    path: `/api/pressportal`,
  },
  jobCount: {
    path: '/api/jobs/count',
  },
};

export default function getConfig(
  serverVars = {
    isPreview: process.env.IS_PREVIEW,
    cdnUrl: '',
    gtmUrl: process.env.GTM_URL,
    baseUrl: process.env.BASE_URL,
    basicAuth: bool(process.env.HAS_BASIC_AUTH) ? process.env.BASIC_AUTH : false,
    hasBasicAuth: process.env.HAS_BASIC_AUTH,
    nodeEnv: 'production',
    caasProjectDb: process.env.CAAS_PROJECT_DB,
    isHeaderSources: false,
  },
) {
  return {
    cdnUrl: '/',
    gtmUrl: url(serverVars.gtmUrl) || 'https://serversidegtm.prod.web.geberit.com',
    endpoints: endpoints,
    serviceTimeout: 50000,
    retryDeceleration: (timeout) => timeout * 2,
    timeToStale: 600000, // 10 min (t/1000/60)
  };
}

export const styleConfig = {
  responsive: {
    mobileMin: 320,
    mobileGdds: 480,
    mobileMax: 767,
    tabletMin: 768,
    tabletMax: 1023,
    tabletGddsMax: 1023,
    desktopMin: 1024,
    desktopGddsMin: 1024,
    largeDesktopGddsMin: 1280,
    desktopMax: 4084,
  },
  /* GDDS grid sizes in REM including the paddings */
  gridSizes: {
    gddsFullGrid: 90, // 1440px -margin standard gdds grid
    gddsCarouselFull: 97.125,
    carouselFull: 85.125,
    full: 78, // for components with 1200px width
    twoThird: 52.5, // for components with 792px width
  },
};
