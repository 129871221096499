import { useEffect, PropsWithChildren } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

// components
import Header from '../features/header';

// utils
import { currentPageSelector } from 'utils/selectors/pageSelectors';
import { useSnap } from './ContentCreator/use-snap';
import { NavigationContextProvider } from './Navigation/NavigationContext';
import { useThemeName } from 'utils/hooks/use-theme';

const NOHEADER_PAGETYPES = ['content_page_no_header_footer'];

export function Layout({ children }: PropsWithChildren<{}>) {
  const page = useSelector(currentPageSelector);
  const router = useRouter();
  const pageHasHeader =
    router.pathname !== '/500' && (!page || (page && !NOHEADER_PAGETYPES.includes(page.type)));
  const themeName = useThemeName();

  useSnap();

  useEffect(() => {
    document.body.className = themeName?.toLowerCase() ?? '';
  }, [themeName]);

  return (
    <>
      {pageHasHeader && (
        <NavigationContextProvider>
          <Header />
        </NavigationContextProvider>
      )}
      {children}
    </>
  );
}
