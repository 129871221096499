export function updateFooter(response) {
  return {
    type: 'footer/UPDATE',
    payload: {
      navigation: response.content.footer.navigation,
      socialMedia: response.content.footer.social_links,
      previewId: response.content.previewId,
    },
  };
}

export type FooterAction = ReturnType<typeof updateFooter>;
