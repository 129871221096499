import React, { PropsWithChildren } from 'react';
import ErrorView from '../../scenes/Error/Error';
import CampusError from '../../scenes/Error/CampusError';
import NordicsError from '../../scenes/Error/NordicsError';
import { withRouter } from 'next/router';

type ErrorBoundaryProps = PropsWithChildren<{
  isCampus?: boolean;
  isNord?: boolean;
  isKolo?: boolean;
  isTwyford?: boolean;
  router: any;
}>;

class ErrorBoundary extends React.Component<ErrorBoundaryProps, { hasError: boolean }> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(p) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: any) {
    // eslint-disable-next-line no-console
    console.warn(error, info);
  }

  componentDidUpdate(previousProps: ErrorBoundaryProps) {
    if (previousProps.router.asPath !== this.props.router.asPath) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError && this.props.isCampus) {
      return <CampusError />;
    }
    // when the error occurs on the client it's stored in the local state, so
    // we know we can go back. if we open a page directly we don't know where we came from and
    // hide the back button
    const goBack = this.state.hasError ? this.props.router.back : undefined;
    if (this.state.hasError || this.props.router.pathname === '/500') {
      return this.props.isNord || this.props.isKolo || this.props.isTwyford ? (
        <NordicsError goBack={goBack} />
      ) : (
        <ErrorView />
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
