// packages
import React, { useRef, useEffect, useContext } from 'react';
import key from 'weak-key';

// components
import { Translation } from '../../Translation/Translation';
import { BUTTON_TYPES, Button } from '../../Button/Button';
import { NavigationContext, UPDATE_LANGUAGESHOWN } from '../NavigationContext';

// styles
import styles from './language-selector.scss';

/**
 * Container for the main navigation mobile
 */

interface LanguageSelectorMobileProps {
  sortedLanguages: any[];
  language: string;
  clickHandler: any;
  isPreview: boolean;
}

function LanguageSelectorMobile({
  sortedLanguages,
  language,
  clickHandler,
  isPreview,
}: Readonly<LanguageSelectorMobileProps>) {
  const { dispatch } = useContext(NavigationContext);
  const switchRef = useRef(null);

  const onAnimationEnd = (animation) => {
    if (animation.animationName.includes('languageswitch-open')) {
      switchRef.current.classList.add('animation--finished');
    }
    if (animation.animationName.includes('languageswitch-close')) {
      switchRef.current.classList.remove('animation--finished');
    }
  };

  useEffect(() => {
    if (switchRef.current) {
      switchRef.current.addEventListener('animationend', onAnimationEnd);
    }

    return () => {
      if (switchRef.current) {
        switchRef.current.removeEventListener('animationend', onAnimationEnd);
      }
    };
  }, [switchRef.current]);

  const languageGoBack = () => {
    dispatch({ type: UPDATE_LANGUAGESHOWN, state: false });
  };

  return (
    <div className={styles.offCanvasLanguage} ref={switchRef}>
      <div className={styles.mobileLanguageSwitch}>
        <Button
          type={BUTTON_TYPES.PLAIN}
          className={styles.back}
          onClick={languageGoBack}
          symbol="chevron-large-left"
        >
          <Translation id="web20_menu_back" />
        </Button>
        <h5 className={styles.selectorheadline}>
          <Translation id="web20_language_selector_headline" />
        </h5>
        <div className="form-items form-items--vertical">
          <div className="form-items--wrapped">
            {sortedLanguages.map((item) => {
              const handleChange = () => {
                clickHandler(item.lang, item.url);
              };

              return (
                <label htmlFor={key(item)} key={key(item)}>
                  <input
                    id={key(item)}
                    type="radio"
                    name="language"
                    checked={item.lang === language}
                    value={item.lang}
                    onChange={handleChange}
                    disabled={!isPreview && item.disabled}
                  />
                  <span>{item.labelFull}</span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LanguageSelectorMobile;
