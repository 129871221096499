import { useRef, useState, useContext, useEffect } from 'react';
import equals from 'lodash.isequal';
import key from 'weak-key';
import { Transition } from 'react-transition-group';

import { NavigationContext, UPDATE_CLICKPATH } from '../../../NavigationContext';
import MenuLayerListEntry from './MenuLayerListEntry';

import styles from './menu-layer.scss';
import { BUTTON_TYPES, Button } from '../../../../Button/Button';
import { Translation } from '../../../../Translation/Translation';
import { NAVIGATION_RESTRICTIONS } from '../../../constants';

interface MenuLayerProps {
  items: any[];
  handleClick: any;
  translationToOverview?: string | null;
  isCampus?: boolean;
}

function MenuLayer({
  items,
  handleClick,
  translationToOverview = null,
  isCampus = false,
}: Readonly<MenuLayerProps>) {
  const {
    state: { clickPath, overridePath, flyoutState },
    dispatch,
  } = useContext(NavigationContext);
  const animatedRef = useRef(null);
  const innerRef = useRef(null);
  const isMounted = useRef(false);
  const [show, setShow] = useState(true);
  const [nextHeight, setNextHeight] = useState('auto');
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [currentLayer, setCurrentLayer] = useState(null);
  const [nextLayer, setNextLayer] = useState(null);

  const handleBackHome = () => {
    dispatch({ type: UPDATE_CLICKPATH, clickPath: [] });
  };

  const handleBreadcrumb = (path) => {
    const newPathIndex = clickPath.findIndex((el) => equals(el, path));
    dispatch({ type: UPDATE_CLICKPATH, clickPath: clickPath.slice(0, newPathIndex + 1) });
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isCampus || !overridePath || overridePath.length === 0) {
      dispatch({ type: UPDATE_CLICKPATH, clickPath: [] });
      setBreadCrumb([]);
      setNextLayer(null);
      setCurrentLayer({ label: null, index: 0, children: items });
    }
    if (!isCampus && overridePath.length > 0) {
      const lengthUsage = overridePath.length === 1 ? 2 : overridePath.length;
      setBreadCrumb(overridePath.slice(0, lengthUsage - 1));
      setCurrentLayer(overridePath[lengthUsage - 2]);

      setNextLayer(null);
    }
  }, [isCampus, flyoutState, overridePath]);

  /**
   * length changed
   */
  useEffect(() => {
    if (currentLayer) {
      if (clickPath.length > 0) {
        setNextLayer(clickPath[clickPath.length - 1]);
      } else if (currentLayer.index !== 0) {
        setNextLayer({ label: null, index: 0, children: items });
      }
    }
  }, [clickPath]);

  useEffect(() => {
    let timeout: Timeout | undefined;
    if (nextLayer) {
      timeout = setTimeout(() => {
        if (isMounted.current) {
          setShow(false);
        }
      }, 200);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [nextLayer]);

  useEffect(() => {
    setTimeout(() => {
      if (innerRef.current) {
        const incomingHeight = innerRef.current.getBoundingClientRect().height;
        setNextHeight(incomingHeight);
      }
    }, 200);
  }, [flyoutState]);

  const timeout = {
    appear: 0,
    enter: 200,
    exiting: 0,
    exit: 200,
  };

  return (
    <Transition
      in={show}
      appear
      onEntered={() => {
        if (innerRef.current) {
          setTimeout(() => {
            const incomingHeight = innerRef.current.getBoundingClientRect().height;
            setNextHeight(incomingHeight);
          }, 0);
        }
        setNextLayer(null);
      }}
      onExited={() => {
        setCurrentLayer({ ...nextLayer, index: clickPath.length });
        setBreadCrumb(clickPath);
        setTimeout(() => {
          if (isMounted.current) {
            if (innerRef.current) {
              const incomingHeight = innerRef.current.getBoundingClientRect().height;
              setNextHeight(incomingHeight);
            }
            setShow(true);
          }
        }, 0);
      }}
      timeout={timeout}
    >
      {(state) => (
        <div
          className={`${styles.AnimatedLayer} layer-${state}`}
          ref={animatedRef}
          style={{ height: nextHeight }}
        >
          <div ref={innerRef}>
            {breadCrumb.length > 0 && (
              <ul className={styles.BackLinks}>
                <li>
                  <Button
                    type={BUTTON_TYPES.PLAIN}
                    className="back"
                    onClick={handleBackHome}
                    noTracking
                  >
                    <Translation id="web20_menu_home" />
                  </Button>
                </li>
                {breadCrumb.slice(0, breadCrumb.length - 1).map((path, bi) => (
                  <li key={key(path)}>
                    <Button
                      type={BUTTON_TYPES.PLAIN}
                      className="back"
                      onClick={() => {
                        handleBreadcrumb(path);
                      }}
                      noTracking
                    >
                      {path.label?.substring(0, NAVIGATION_RESTRICTIONS[bi])}
                    </Button>
                  </li>
                ))}
              </ul>
            )}
            {currentLayer?.label && (
              <span className={styles.LayerName}>
                {currentLayer.label?.substring(0, NAVIGATION_RESTRICTIONS[currentLayer.index])}
              </span>
            )}
            {currentLayer && (
              <ul className={styles.MenuLayer}>
                {currentLayer.index > 0 && currentLayer.url && (
                  <MenuLayerListEntry
                    handleClick={handleClick}
                    item={{ label: translationToOverview, url: currentLayer.url }}
                    index={currentLayer.index}
                    key={`mlle-${currentLayer.url}`}
                  />
                )}
                {currentLayer?.children?.map((item) => (
                  <MenuLayerListEntry
                    key={key(item)}
                    handleClick={handleClick}
                    item={item}
                    index={currentLayer.index}
                  />
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </Transition>
  );
}

export default MenuLayer;
