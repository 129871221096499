// Components
import { CmsLink } from '../../../Link/CmsLink';
import { EditWrapper } from '../../../ContentCreator/EditWrapper';
import { BUTTON_TYPES, Button } from '../../../Button/Button';

// styles
import styles from '../main-navigation.scss';

interface ExternalNavLinkProps {
  item: any;
  hasButtonWrapper?: boolean;
}

function ExternalNavLink({ item, hasButtonWrapper = false }: Readonly<ExternalNavLinkProps>) {
  const linkParameters = {
    type: item.external || 'internal_link',
    target: item.url,
    window: item.window ? '_blank' : '_self',
    text: item.label,
  };

  const buttonWrapper = (children) => {
    if (hasButtonWrapper) {
      return (
        <Button type={BUTTON_TYPES.PLAIN} className={styles.externalNavLink}>
          {children}
        </Button>
      );
    }

    return children;
  };

  return (
    <EditWrapper previewId={item.previewId}>
      {buttonWrapper(
        <CmsLink link={linkParameters} allowEmptyLink>
          {item.label}
        </CmsLink>,
      )}
    </EditWrapper>
  );
}

export default ExternalNavLink;
