import { Service } from 'services';
import { getServerVars } from 'utils/get-server-vars';

export async function createInitialStore(homepages: any, lang: string, req: any) {
  const [globalProjectData, mainNav, metaNav, translations] = await Promise.all([
    Service('technicalservice', req).json(`/technical?type=global_project_data&language=${lang}`),
    Service('navigationservice', req)
      .json(`/navigation/main?language=${lang}`)
      .then((data) => data.mainnav),
    Service('navigationservice', req).json(`/navigation/meta?language=${lang}`),
    Service('keyvalueservice', req).json(`/translation?language=${lang}`),
  ]);

  const { theme, ...server } = getServerVars(req);
  const { footer, socialMedia, ...globals } =
    globalProjectData.languages.find((i) => Object.keys(i)[0] === lang)[lang] || {};

  return {
    footerNavigation: { ...footer, socialMedia },
    translations: translations ? { [lang]: translations } : {},
    navigation: {
      [lang]: {
        main: { items: mainNav },
        meta: metaNav,
      },
    },
    homepageList: { item: homepages, currentLanguage: lang },
    globals: {
      [lang]: globals,
      server,
      theme,
    },
  };
}
