import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useSelect } from 'downshift';
import { useRouter } from 'next/router';

// gdds components
import { Button } from '@geberit/gdds';

// components
import { DropdownFlyout, LanguageSwitchWrapper } from './language-switch-desktop.styles';
import { NavItem } from '../switch/nav-item';

// utils
import { alternativePageLanguagesSelector } from 'utils/selectors/pageSelectors';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useTracking } from 'utils/hooks/useTracking';
import { languageSwitchTrackingAction } from 'components/Navigation/trackingActions';
import { useReactKeys } from './use-react-keys';
import { replaceWithEnDash } from './language-switch-utils';
import { useIsPreview } from 'utils/hooks/useIsPreview';

export function LanguageSwitchDesktop() {
  const availableLanguages = useSelector(alternativePageLanguagesSelector);
  const currentLanguage = useCurrentLanguage();
  const track = useTracking();
  const isPreview = useIsPreview();
  const availableLanguagesWithKeys = useReactKeys(availableLanguages ?? [], [
    'labelFull',
    'lang',
    'url',
  ]);
  const router = useRouter();

  type NewType = typeof availableLanguagesWithKeys;

  const [selectedItem, setSelectedItem] = useState<NewType[number] | null | undefined>(null);

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: availableLanguagesWithKeys,
    itemToString: (l) => l?.labelFull ?? '',
    selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (newSelectedItem?.url) router.push(newSelectedItem?.url);
      setSelectedItem(newSelectedItem);
    },
    isItemDisabled: ({ disabled }) => disabled,
  });

  if (!availableLanguages || availableLanguages.length === 1) {
    return null;
  }

  if (!availableLanguages || availableLanguages.length === 1) {
    return null;
  }

  return (
    <LanguageSwitchWrapper isOpen={isOpen}>
      <Button
        iconAlignment="right"
        symbol="chevronDown"
        className={isOpen ? 'selected' : undefined}
        {...getToggleButtonProps()}
        stylingType="flatLight"
      >
        {currentLanguage.slice(0, 2)}
      </Button>

      <DropdownFlyout {...getMenuProps()} isOpen={isOpen}>
        {availableLanguagesWithKeys?.map((language, index) => {
          const itemProps = getItemProps({ item: language, index });
          return (
            <NavItem
              href={language.url}
              key={language.key}
              onClick={() => {
                track.trackEvent(languageSwitchTrackingAction(language.lang));
              }}
              selected={currentLanguage === language.lang}
              leftIconName="checkmark"
              isOption
              tabIndex={isOpen ? undefined : -1}
              highlighted={highlightedIndex === index}
              disabled={!isPreview && language.disabled}
              {...itemProps}
            >
              {replaceWithEnDash(language.labelFull)}
            </NavItem>
          );
        })}
      </DropdownFlyout>
    </LanguageSwitchWrapper>
  );
}
