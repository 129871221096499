import styled, { css } from 'styled-components';
import Link from 'next/link';
import { media } from 'mm/utils/no-touch-device';
import { memoizedFilterForSvgColor } from 'mm/utils/hex-to-filter-converter';
import { clampLine } from 'mm/utils/clamp-line';

type IconPosition = 'left' | 'right' | 'both' | 'none';

const baseStyles = ({ theme, $iconPosition, pointerEvents }) => css`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 2.75rem;
  display: flex;
  align-items: center;
  padding: 0.6875rem 2.75rem 0.6875rem 0.5rem;
  font-size: ${theme.fontSizes[2]}px;
  line-height: ${theme.lineHeights[1]}px;
  font-weight: ${theme.fontWeights.medium};
  border: 1px solid transparent;
  outline-offset: 0;
  transition: none;
  ${$iconPosition === 'left' ? 'padding-left: 2.75rem' : ''};
  ${$iconPosition === 'both' ? 'padding-left: 2.75rem;' : ''};
  hyphens: manual;
  pointer-events: ${pointerEvents};
`;

const imgStyles = ({ selected, filterSvgColorPrimaryLight }) => css`
  img {
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    margin: 0.75rem;
    left: 0;
    top: 0;
    ${selected && filterSvgColorPrimaryLight}

    ${media.noTouch`
      margin: 0.625rem;
    `}
  }
`;

const spanStyles = () => css`
  span {
    ${clampLine()}
    min-height: unset;

    &.first-icon,
    &.last-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      padding: 0.75rem;

      ${media.noTouch`
        padding: 0.625rem;
      `}

      svg {
        width: 1.25rem;
        fill: currentColor;
      }
    }

    &.first-icon {
      left: 0;
      top: 0;
    }

    &.last-icon {
      right: 0;
    }
  }
`;

export const StyledLink = styled(Link)<{
  selected?: boolean;
  isOption?: boolean;
  $iconPosition: IconPosition;
  hasPictogram?: boolean;
  firstLevel?: boolean;
  thirdLevel?: boolean;
  isOverviewItem?: boolean;
  notClickable?: boolean;
}>`
  ${({ theme, selected, $iconPosition, isOption, hasPictogram, thirdLevel, notClickable }) => {
    const filterSvgColorWhite = memoizedFilterForSvgColor(theme.colors.white);
    const filterSvgColorPrimaryLight = memoizedFilterForSvgColor(theme.colors.primaryLight);
    const pointerEvents = notClickable ? 'none' : 'auto';

    return css`
      ${baseStyles({
        theme,
        $iconPosition,
        pointerEvents,
      })}

      && {
        color: ${selected ? theme.colors.primaryLight : theme.colors.black};
        border-radius: 0px;
        border-bottom-color: ${selected && !isOption ? theme.colors.primaryLight : 'transparent'};
      }

      ${media.noTouch`
        min-height: 2.5rem;
        padding: 0.5625rem 2.5rem 0.5625rem 0.5rem;
        ${$iconPosition === 'left' ? 'padding-left: 2.5rem;' : ''};
        ${$iconPosition === 'both' ? 'padding-left:2.5rem;' : ''};
      `}

      &:before {
        content: none;
      }

      &&:visited:focus {
        text-decoration: none;
      }

      &:focus,
      &:link {
        text-decoration: none;
      }

      ${media.hover`
        text-decoration: none;
        background-color: rgba(${theme.colors.transparentBlack}, 0.08);
      `}

      &&:focus-visible {
        border: 1px solid ${theme.colors.primaryLight};
        outline: 1px solid ${theme.colors.primaryLight};
        text-decoration: none;
      }

      &:active {
        ${({ theme }) => theme.global.disableTapHighlight}
        text-decoration: none;
        background-color: ${theme.colors.cl15};
        color: ${theme.colors.white};

        > img {
          ${filterSvgColorWhite}
        }
      }

      ${imgStyles({ selected, filterSvgColorPrimaryLight })}
      ${spanStyles({ isOption, selected })}
    `;
  }}
`;
