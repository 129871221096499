import { useState, useEffect, useContext, useRef } from 'react';
import _throttle from 'lodash.throttle';
import { Button as GDDSButton } from '@geberit/gdds';

// components
import NavigationColumn from './NavigationColumn';
import { BUTTON_TYPES, Button } from '../../../Button/Button';

// utils
import {
  NavigationContext,
  UPDATE_CLICKPATH,
  UPDATE_NAVIGATION_FLYOUT,
  NAVIGATION_CLOSED,
  NAVIGATION_OPEN,
} from '../../NavigationContext';
import { useIsomorphicLayoutEffect } from 'utils/hooks/use-isomorphic-layout-effect';

// styles
import styles from '../main-navigation.scss';
import { useNord } from '../../../../utils/hooks/use-nord';

interface FirstLevelFlyoutProps {
  label?: string | null;
  closeFlyout: () => void;
  children: React.ReactNode;
}

function FirstLevelFlyout({ label = null, children, closeFlyout }: FirstLevelFlyoutProps) {
  const flyoutRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);
  const isNord = useNord();

  const {
    dispatch,
    state: { clickPath, flyoutState },
  } = useContext(NavigationContext);

  const onEndAnimation = (e) => {
    if (isNord) {
      return;
    }

    if (e.target === flyoutRef.current && flyoutState === NAVIGATION_OPEN) {
      if (flyoutRef.current?.classList.contains(styles.flyoutTransition))
        flyoutRef.current.classList.remove(styles.flyoutTransition);
    }
  };

  const onOutsideClick = (e) => {
    const navBar = document.querySelector('#page-header #logo + div');
    const langSwitch = document.querySelector('#page-header > nav > div > div > div');
    if (navBar && !navBar.contains(e.target) && langSwitch && !langSwitch.contains(e.target)) {
      closeFlyout();
    }
  };

  /**
   * Update Fyloutheight
   */
  const setFlyoutHeight = () => {
    if (flyoutRef.current) {
      const headerHeight =
        document.querySelector('#page-header')?.getBoundingClientRect().height ?? 0;
      flyoutRef.current.style.height = `${window.innerHeight - headerHeight}px`;
    }
  };

  const setThrottledHeight = _throttle(setFlyoutHeight);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setThrottledHeight);
    }

    return () => {
      window.removeEventListener('resize', setThrottledHeight);
    };
  }, []);

  /**
   * Mount effect
   */
  useIsomorphicLayoutEffect(() => {
    if (flyoutRef.current) {
      setFlyoutHeight();
    }
    if (flyoutRef.current && flyoutState !== NAVIGATION_CLOSED) {
      setFlyoutHeight();

      if (!flyoutRef.current.classList.contains(styles.flyoutTransition))
        flyoutRef.current.classList.add(styles.flyoutTransition);

      if (!flyoutRef.current.classList.contains(styles.flyoutDown))
        flyoutRef.current.classList.add(styles.flyoutDown);

      setMounted(true);
    }
  }, [flyoutRef.current]);

  /**
   * Should close
   */
  useEffect(() => {
    let timeout: Timeout | undefined;
    if (clickPath.length === 0) {
      dispatch({ type: UPDATE_NAVIGATION_FLYOUT, state: NAVIGATION_CLOSED });
    }

    if (flyoutState === NAVIGATION_CLOSED && flyoutRef.current) {
      if (!flyoutRef.current.classList.contains(styles.flyoutTransition))
        flyoutRef.current.classList.add(styles.flyoutTransition);
      if (!flyoutRef.current.classList.contains(styles.flyoutTransitionDelay))
        flyoutRef.current.classList.add(styles.flyoutTransitionDelay);

      if (flyoutRef.current.classList.contains(styles.flyoutDown))
        flyoutRef.current.classList.remove(styles.flyoutDown);

      timeout = setTimeout(() => {
        if (mounted === true && !isNord) {
          dispatch({ type: UPDATE_CLICKPATH, clickPath: [] });
        }
      }, 600);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [mounted, flyoutState, clickPath]);

  /**
   * Animation listeners
   */
  useEffect(() => {
    if (flyoutRef.current) {
      flyoutRef.current.addEventListener('transitionend', onEndAnimation);
      flyoutRef.current.addEventListener('transitioncancel', onEndAnimation);
    }
    window.addEventListener('click', onOutsideClick);

    return () => {
      if (flyoutRef.current) {
        flyoutRef.current.removeEventListener('transitionend', onEndAnimation);
        flyoutRef.current.removeEventListener('transitioncancel', onEndAnimation);
      }
      window.removeEventListener('click', onOutsideClick);
    };
  }, [onEndAnimation, onOutsideClick]);

  return (
    <div className={styles.flyout} ref={flyoutRef}>
      <div className={`grid-container ${styles.navigationContainer}`}>
        {isNord ? (
          <GDDSButton
            stylingType="icon"
            onClick={closeFlyout}
            isIcon
            symbol="Close"
            height={{ xsmall: 3 }}
            className={styles.close}
          ></GDDSButton>
        ) : (
          <Button
            symbol="close"
            onClick={closeFlyout}
            className={styles.close}
            type={BUTTON_TYPES.PLAIN}
          />
        )}
        <NavigationColumn label={label} activeClickPathClass={styles.activeClickPath} isRoot>
          {children}
        </NavigationColumn>
      </div>
    </div>
  );
}

export default FirstLevelFlyout;
