import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import NordicsHeader from './components/header/nordics';
import GroupHeader from './components/header/group';
import { MegaMenuHeader } from './components/header/mega-menu/mega-menu-header';
import CountryHeader from './components/header/country';

import { useGdds } from 'utils/hooks/use-gdds';
import { useNord } from 'utils/hooks/use-nord';
import { showMegaMenuSelector } from 'utils/selectors/globalsSelectors';

export default function Header() {
  const isNord = useNord();
  const isGdds = useGdds();
  const showMegaMenu = useSelector(showMegaMenuSelector);

  useEffect(() => {
    if (showMegaMenu?.enabled) {
      document.querySelector('main')?.classList.add('with-mega-menu');
    }
  });

  if (isNord) {
    return <NordicsHeader />;
  }

  if (isGdds) {
    return <GroupHeader />;
  }

  if (showMegaMenu?.enabled) {
    return <MegaMenuHeader />;
  }

  return <CountryHeader />;
}
