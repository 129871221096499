import React, { useState, useRef, useEffect, useContext } from 'react';
import _throttle from 'lodash.throttle';
import { BackwardButton, Typo, defaultTheme as theme } from '@geberit/gdds';

// utils
import {
  NavigationContext,
  UPDATE_CLICKPATH,
  NAVIGATION_CLOSED,
  NAVIGATION_OPEN,
} from '../../NavigationContext';
import { useNord } from '../../../../utils/hooks/use-nord';

// styles
import styles from '../main-navigation.scss';
import { NAVIGATION_RESTRICTIONS } from '../../constants';

// components
import { Translation } from '../../../Translation/Translation';
import { classNameBuilder } from '../../../../utils/classNameBuilder';

interface NavigationColumnProps {
  isRoot?: boolean | null;
  activeClickPathClass?: string;
  label: string;
  index: number;
  children?: React.ReactNode;
}

function NavigationColumn({
  label,
  children = null,
  isRoot = null,
  activeClickPathClass = '',
  index = 0,
}: NavigationColumnProps) {
  const [hasIndicator, setHasIndicator] = useState(false);
  const isNord = useNord();
  const backwardClick = React.useRef(false);
  const {
    state: { flyoutState, clickPath, nextPath },
    dispatch,
  } = useContext(NavigationContext);
  const columnRef = useRef(null);
  const containerRef = useRef(null);
  const columnOutRef = useRef(null);
  const isMounted = useRef(false);
  const HeadingComponent = isRoot ? 'h4' : 'b';
  const timeout = useRef<Timeout | null>(null);

  const onAnimationEnd = () => {
    if (
      nextPath[nextPath.length - 1]?.label !== clickPath[clickPath.length - 1].label ||
      nextPath.length < clickPath.length
    ) {
      timeout.current = setTimeout(() => {
        if (isMounted.current === true) {
          dispatch({ type: UPDATE_CLICKPATH, clickPath: [...nextPath] });
        }
      }, 200);
    }

    if (backwardClick.current) {
      timeout.current = setTimeout(() => {
        dispatch({ type: UPDATE_CLICKPATH, clickPath: clickPath.slice(0, 2) });
        backwardClick.current = false;
      }, 200);
    }
  };

  const onIndicatorChange = () => {
    timeout.current = setTimeout(() => {
      if (isMounted.current === true) {
        if (
          columnRef.current &&
          containerRef.current &&
          columnRef.current.scrollHeight > containerRef.current.scrollHeight
        ) {
          setHasIndicator(true);
        } else {
          setHasIndicator(false);
        }
      }
    }, 0);
  };

  const setThrottledIndicator = _throttle(onIndicatorChange);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let timeout: Timeout | undefined;
    if (
      columnOutRef.current &&
      flyoutState === NAVIGATION_OPEN &&
      (nextPath.length < 1 ||
        nextPath[nextPath.length - 1]?.label !== clickPath[clickPath.length - 1]?.label ||
        nextPath.length < clickPath.length) &&
      index > nextPath.length - 2
    ) {
      columnOutRef.current.classList.remove(styles.columnIn, styles.columnInNoDelay);
    } else if (columnOutRef.current && flyoutState === NAVIGATION_OPEN) {
      timeout = setTimeout(() => {
        if (columnOutRef.current) {
          columnOutRef.current.classList.add(styles.columnIn, styles.columnInNoDelay);
        }
      }, 50);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [columnOutRef.current, nextPath, clickPath, flyoutState]);

  useEffect(() => {
    if (columnOutRef.current) {
      columnOutRef.current.addEventListener('transitionend', onAnimationEnd);
      columnOutRef.current.addEventListener('transitioncancel', onAnimationEnd);
    }
    window.addEventListener('resize', setThrottledIndicator);

    return () => {
      if (columnOutRef.current) {
        columnOutRef.current.removeEventListener('transitionend', onAnimationEnd);
        columnOutRef.current.removeEventListener('transitioncancel', onAnimationEnd);
      }
      window.removeEventListener('resize', setThrottledIndicator);
    };
  }, [columnOutRef.current, nextPath, clickPath]);

  useEffect(() => {
    const onScroll = () => {
      const isBottom =
        columnRef.current.scrollHeight - columnRef.current.scrollTop <
        containerRef.current.scrollHeight;
      setHasIndicator(!isBottom);
    };

    if (columnRef.current) {
      columnRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (columnRef.current) {
        columnRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [containerRef.current, columnRef.current]);

  useEffect(() => {
    // has to run after dropdown animation
    const timeout = setTimeout(() => {
      if (isMounted.current) {
        if (
          columnRef.current &&
          containerRef.current &&
          columnRef.current.scrollHeight > containerRef.current.scrollHeight
        ) {
          setHasIndicator(true);
        } else {
          setHasIndicator(false);
        }
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [columnRef.current, containerRef.current]);

  // mount & unmount effects
  useEffect(() => {
    let timeout: Timeout | undefined;
    if (columnOutRef.current && flyoutState === NAVIGATION_CLOSED) {
      columnOutRef.current.classList.remove(styles.columnIn);
      timeout = setTimeout(() => {
        if (columnOutRef.current) {
          columnOutRef.current.classList.add(styles.columnInNoDelay);
        }
      }, 200);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [flyoutState, columnOutRef.current]);

  const handleBackButton = () => {
    backwardClick.current = true;
    columnOutRef.current.classList.remove(activeClickPathClass, styles.columnIn);
    document
      .querySelector('.navcolumn-1 > div')
      .classList.remove(styles.columnIn, styles.columnInNoDelay);

    columnOutRef.current.classList.add(styles.columnOut);
  };

  return (
    <div
      className={classNameBuilder(
        `${activeClickPathClass}`,
        `navcolumn-${index}`,
        isNord && `navcolumn-${index}-nordics`,
      )}
      ref={containerRef}
    >
      <div className={styles.column} ref={columnOutRef}>
        {isNord && index > 1 && (
          <BackwardButton
            onClick={handleBackButton}
            height={48}
            symbol="ChevronLeft"
            stylingType="flat"
            className="backward-button"
          >
            <Translation id="web20_menu_back" />
          </BackwardButton>
        )}
        <div className={styles.scrollItem} ref={columnRef}>
          {isNord ? (
            <Typo tag="p" styles={theme.fontWeights.regular}>
              {label.substr(0, NAVIGATION_RESTRICTIONS[index + 1])}
            </Typo>
          ) : (
            <HeadingComponent>
              {label.substr(0, NAVIGATION_RESTRICTIONS[index + 1])}
            </HeadingComponent>
          )}
          {children}

          {hasIndicator && (
            <span className={styles.scrollIndicator}>
              <span>
                <span />
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default NavigationColumn;
