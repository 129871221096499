import { isEmpty } from './is-empty';

export const configureTitle = ({
  title,
  jobTitle,
  name,
  titleClaim,
}: Partial<{ title?: string; titleClaim?: string; name: string; jobTitle: string }>) => {
  const metaTitle = jobTitle ? name : title;
  const suffix = !isEmpty(titleClaim) ? ` | ${titleClaim}` : '';

  if (!isEmpty(metaTitle)) {
    return `${metaTitle}${suffix}`;
  }

  return titleClaim;
};

export const configureDescription = (pagename?: string, description?: string, claim?: string) => {
  const addClaim = !isEmpty(claim) ? ` - ${claim}` : '';

  if (!isEmpty(description)) {
    return description;
  }

  if (!isEmpty(pagename)) {
    return `${pagename}${addClaim}`;
  }

  return '';
};
