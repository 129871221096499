export function bool(env: string | undefined | null) {
  if (env) {
    return env === '1' || env.toLowerCase() === 'true';
  }
  return false;
}

export function url(env: string | undefined): string | undefined {
  if (env?.endsWith('/')) {
    return env.substring(0, env.length - 1);
  }
  return env;
}
