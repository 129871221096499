import { HTMLProps } from 'react';
import { useSelector } from 'react-redux';

// components
import { TranslationEditWrapper } from '../ContentCreator/TranslationEditWrapper';

// utils
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import { decodingContent } from 'utils/decodingContent';
import { useIsPreview } from 'utils/hooks/useIsPreview';

interface ITranslationProps extends Omit<HTMLProps<HTMLSpanElement>, 'placeholder'> {
  id: string;
  placeholder?: Record<string, string>;
  translations?: Record<string, string>;
  isPreview?: boolean;
  isStaticPage?: boolean;
}

const replaceString = (
  translations: Record<string, string>,
  id: string,
  placeholder: Record<string, string>,
): string => {
  let trans = translations[id];
  if (Object.keys(placeholder).length > 0) {
    Object.entries(placeholder).forEach(([replacement, translation]) => {
      trans = trans.replace(`%%${replacement}%%`, translation);
      trans = trans.replace(`%${replacement}%`, translation);
    });
  }

  return trans;
};

export function Translation(props: Readonly<ITranslationProps>) {
  const { id, placeholder = {}, isStaticPage = false, ...spanProps } = props;
  const translations = useSelector(translationKeysSelector) || {};
  const isPreview = useIsPreview();

  if (!translations[id]) {
    return isPreview && !isStaticPage ? (
      <TranslationEditWrapper {...spanProps} translationId={id} hasError>
        {id}
      </TranslationEditWrapper>
    ) : null;
  }

  const content = decodingContent(replaceString(translations, id, placeholder));

  if (isPreview && !isStaticPage) {
    return (
      <TranslationEditWrapper {...spanProps} translationId={id}>
        {content}
      </TranslationEditWrapper>
    );
  }

  return <>{content}</>;
}
