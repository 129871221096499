import isEmpty from 'lodash.isempty';
import { currentLanguageSelector } from './languageSelectors';

interface ILocatorSearch {
  placeholder: string;
  suggestionsLabel: string;
  recentResultsLabel: string;
  searchNote: string;
}

interface ILocatorConsent {
  consentText: string;
  buttonLabel: string;
  geoLocationFailureNote: string;
  type: unknown;
}

interface ILocatorFilter {
  showPartnerSwitch?: boolean;
  filterVisible: boolean;
  dropCategoryText: string;
  dropSeriesText: string;
  dropCountryText: string;
  allFilters: string;
  filter1Text: string;
  filter2Text: string;
  filter3Text: string;
  prioritisePartners: string;
  tooltipText: string;
  apply: string;
  reset: string;
}

interface ILocatorMap {
  routePlanner: string;
  showOnMap: string;
  resultsNear: string;
  moreDetails: string;
  results: string;
}

interface Meta {
  descriptionSuffix: string;
  titlePrefix: string;
  contact: string;
  categories: string;
  email: string;
}

interface IDealerPage {
  contact: string;
  categories: string;
  furtherEquipment: string;
  requestAdvice: string;
  calculateRoute: string;
  note: string;
  noteText: string;
  toOverviewPage: string;
  detailPageHash: string;
  email: string;
  website: string;
  address: string;
  meta: Meta;
  showAll: string;
  bathroomProducts: string;
  productSeries: string;
  initZoomLevel?: number;
}

interface IUrlSettings {
  label: string;
  ariaLabel: string;
}

interface ILocatorLabels {
  dealerWithShowroom: string;
  installer: string;
  brandShowroom: string;
  partner: string;
  installerWithShowroom: string;
  brand: string;
}

export interface ILocatorConfiguration {
  filter: ILocatorFilter;
  consent: ILocatorConsent;
  search: ILocatorSearch;
  map: ILocatorMap;
  url: string;
  urlSettings: IUrlSettings;
  dealerPage: IDealerPage;
  initialPins?: number;
  locatorUrls: string[];
  labels: ILocatorLabels;
}

export const globalsSelector = (state: AppState): LanguageSpecificGlobals | null => {
  const currentLanguage = currentLanguageSelector(state);

  if (!currentLanguage || !state.globals) return null;

  return state.globals[currentLanguage] || null;
};

export const globalsServerSelector = (state: AppState) => {
  const globals = state.globals;
  if (!globals) return null;

  return globals?.server;
};

export const globalsByTypeSelector = (state: AppState, type: string) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  switch (type) {
    case 'footer':
      return state.footerNavigation.socialMedia || null;

    case 'campus':
      return globals.campus || null;

    case 'global_project_data':
    default:
      return globals || null;
  }
};

export const campusContactSelector = (state: AppState) => {
  const campusGlobals = globalsByTypeSelector(state, 'campus');

  if (!campusGlobals) return null;

  return campusGlobals.campusContacts;
};

export const campusReferenceUrlSelector = (state: AppState) => {
  const campusGlobals = globalsByTypeSelector(state, 'campus');
  if (!campusGlobals?.campusReferenceUrl) return '/';

  // ensure that the url always starts and ends with /
  let referenceUrl = campusGlobals.campusReferenceUrl;
  if (referenceUrl.charAt(0) !== '/') referenceUrl = `/${referenceUrl}`;
  if (referenceUrl.slice(-1) !== '/') referenceUrl += '/';

  return referenceUrl;
};

export const campusDashboardUrlSelector = (state: AppState) => {
  const campusGlobals = globalsByTypeSelector(state, 'campus');
  if (!campusGlobals?.campusDashboardUrl) return '';

  return campusGlobals.campusDashboardUrl;
};

export const campusCatalogUrlSelector = (state: AppState) => {
  const campusGlobals = globalsByTypeSelector(state, 'campus');
  if (!campusGlobals?.campusCatalogUrl) return '/';

  return campusGlobals.campusCatalogUrl;
};

export const campusShowFactoryTourSelector = (state: AppState) => {
  const campusGlobals = globalsByTypeSelector(state, 'campus');

  return !!campusGlobals?.showFactoryTourOption;
};

export const campusBaseUrlSelector = (state: AppState): string => {
  return state.globals.server.campus.CAMPUS_BASE_URL;
};

/**
 * Google Validation
 */
export const googleValidationSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.googleValidation || null;
};

/**
 * Pinterest Validation
 */
export const pinterestValidationSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.pinterestValidation || null;
};

/**
 * Baidu Validation
 */
export const baiduValidationSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.baiduValidation || null;
};

/**
 * Footer country switch
 */
export const footerCountrySwitchSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.footerCountrySwitch || null;
};

/**
 *  social Media
 */
export const socialMediaItemsSelector = (state: AppState) => {
  return state.footerNavigation.socialMedia;
};

/**
 *  oxomi
 */
export const oxomiItemsSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.oxomiDropdown || null;
};

/**
 * Youtube settings
 */
export const youtubeSettingsSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.youtube || null;
};

/**
 * Group worldwide
 */
export const groupWorldwideSelector = (state: AppState) => {
  const group = groupDataSelector(state);

  if (!group) return null;

  return group.worldwide || null;
};

/**
 * Group data selector
 */
export const groupDataSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.group || null;
};

/**
 *  Search
 */
export const globalsSearchSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.search || null;
};

/**
 *  Search
 */
export const globalsSearchSegmentSelector = (state: AppState) => {
  const search = globalsSearchSelector(state);

  if (!search) return null;

  return search.segment || null;
};

/**
 *  Search
 */
export const globalsSearchUrlSelector = (state: AppState) => {
  const search = globalsSearchSelector(state);

  if (!search) return null;

  return search.searchResultPageUrl || null;
};

/**
 *  Search
 */
export const globalsSearchDisabled = (state: AppState) => {
  const search = globalsSearchSelector(state);

  if (!search) return false;

  return search.searchDisabled || false;
};

export const globalsSearchResultArticles = (state: AppState) => {
  const search = globalsSearchSelector(state);

  if (!search) return null;

  return search.articlesDisabled || false;
};

export const globalsSearchResultContent = (state: AppState) => {
  const search = globalsSearchSelector(state);

  if (!search) return null;

  return search.contentDisabled || false;
};

/**
 * MyOrderHistory Url
 */
export const orderHistoryUrlSelector = (state: AppState) => {
  const globals = webshopSelector(state);

  if (!globals) return '';

  return globals.orderHistoryUrl || '';
};

/**
 * Webshop
 */
const webshopSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.webshop || null;
};

/**
 * Webshop enabled
 */
export const webshopEnabledSelector = (state: AppState) => {
  const webshop = webshopSelector(state);

  if (!webshop) return null;

  return webshop.enabled;
};

/**
 * Webshop Basket page Url
 */
export const webshopBasketPageUrlSelector = (state: AppState) => {
  const webshop = webshopSelector(state);
  if (!webshop) return null;

  return webshop.basketPageUrl;
};

/**
 * Webshop checkout Intro blocked page url
 */
export const webshopBlockedCheckoutPageUrlSelector = (state: AppState) => {
  const webshop = webshopSelector(state);
  if (!webshop) return null;

  return webshop.checkoutIntroBlockUrl;
};

/**
 * Webshop checkout Intro page url
 */
export const webshopCheckoutPageUrlSelector = (state: AppState) => {
  const webshop = webshopSelector(state);
  if (!webshop) return null;

  return webshop.checkoutIntroPageUrl;
};

/**
 * Webshop checkout Intro page url
 */
export const webshopCheckoutUrlSelector = (state: AppState) => {
  const webshop = webshopSelector(state);
  if (!webshop) return null;

  return webshop.checkoutPageUrl;
};

/**
 * Webshop Landingpage Url
 */
export const webshopLandingpageUrlSelector = (state: AppState) => {
  const webshop = webshopSelector(state);
  if (!webshop) return null;

  return webshop.webshopOverviewUrl;
};

/**
 * PDP Filters
 */
export const pdpFiltersSelector = (state: AppState) => {
  const webshop = webshopSelector(state);

  if (!webshop) return false;

  return webshop.pdpFilters || false;
};

export const webshopShippingInfoSelector = (state: AppState) => {
  const webshop = webshopSelector(state);

  if (!webshop) return false;

  return webshop.freeShipping || false;
};

/**
 * Online Catalog Button
 */
export const ocbSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.onlineCatalog?.ocb ?? null;
};

/**
 * Splash Screen
 */
export const splashScreenSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;
  if (!globals.splashScreen) return null;

  return globals.splashScreen;
};

/**
 * Captcha
 */
export const captchaKeySelector = (state: AppState) => {
  const globals = globalsSelector(state);
  return globals?.captchaPattern ?? null;
};

/**
 * CIAM
 */
export const ciamSelector = (state: AppState): Ciam | undefined => {
  const globals = globalsSelector(state);

  if (!globals) return undefined;

  return globals.ciam || undefined;
};

export const ciamScriptSelector = (state: AppState) => {
  const ciam = ciamSelector(state);

  if (isEmpty(ciam)) return undefined;
  return ciam.script_url;
};

export const ciamKeySelector = (state: AppState) => {
  const ciam = ciamSelector(state);

  if (!ciam) return undefined;
  return ciam.api_key;
};

export const ciamEnabledSelector = (state: AppState) => {
  const ciam = ciamSelector(state);

  if (!ciam) return null;
  return ciam.enabled;
};

export const ciamProfileUrlSelector = (state: AppState) => {
  const ciam = ciamSelector(state);

  if (!ciam) return null;
  return ciam.ciamProfileUrl;
};

export const gigyaReadySelector = (state: AppState) => {
  const ciam = ciamSelector(state);

  if (!ciam) return null;
  return ciam.gigyaReady;
};

export const videoAutoplaySelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;
  return globals.videoAutoplay;
};

export const faviconSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;
  return globals.icons;
};

export const catalogBrandNameSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.onlineCatalog?.brandName ?? null;
};

export const catalogBaseUrlSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.onlineCatalog?.baseUrl ?? null;
};

/**
 * Error Page
 */
export const errorPageSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.errorPage;
};

/**
 * Dateformat
 */
export const dateformatSelector = (state: AppState) => {
  const globals = globalsSelector(state);
  if (!globals) return 'yyyy.MM.dd';

  return globals.dateformat || 'yyyy.MM.dd';
};

/**
 * Document Title
 */
export const themeSelector = (state: AppState) => {
  if (!state.globals) return null;

  return state.globals.theme;
};

export const baseURLSelector = (state: AppState) => {
  if (!state.globals) return null;

  return state.globals.server.baseUrl;
};

/**
 * Consent Selector
 */
export const consentFallbacksSelector = (state: AppState) => {
  const lang = globalsSelector(state);
  return lang?.consentFallbacks ?? null;
};

export const locatorConfigurationSelector = (state): ILocatorConfiguration | null => {
  const globals = globalsSelector(state);
  return globals?.locatorTranslations || globals?.locator || null;
};

export const headerPinSelector = (state: AppState) => {
  const globals = globalsSelector(state);
  return (
    globals?.pin ?? {
      enabled: false,
      entries: [],
      previewId: '',
    }
  );
};

export const pressPortalSelector = (state: AppState): Partial<LanguageSpecificGlobals['press']> => {
  const globals = globalsSelector(state);

  if (!globals) return {};

  return globals.press;
};

/**
 * custom logo selector
 */
export const logoSelector = (state: AppState) => {
  const globalState = globalsSelector(state);

  return globalState?.logo ?? null;
};

export const yextSelector = (state: AppState) => {
  const globalState = globalsSelector(state);

  return globalState?.yext?.enabled && globalState?.yext?.url ? globalState.yext.url : null;
};

export const monochromeLogoSelector = (state: AppState) => {
  const globalState = globalsSelector(state);

  return globalState?.monochromeLogo ?? null;
};

/**
 * Anchor Bar Selector
 */
export const anchorBarSelector = (state) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals.anchorBar || null;
};

/**
 * Usercentrics - settings ID
 */
export const usercentricsSettingsId = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals?.uc_settingsId || null;
};

/**
 * Show Mega Menu
 */
export const showMegaMenuSelector = (state: AppState) => {
  const globals = globalsSelector(state);

  if (!globals) return null;

  return globals?.useMegaMenu || null;
};
