import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

// components
import CiamLoginView from './ciam-login-view';
import CiamGddsLoginView from './ciam-login-gdds-view';

// utils
import { SessionContext } from 'components/Session/SessionContext';
import { useIsDesktop, useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { useNord } from 'utils/hooks/use-nord';
import { useGdds } from 'utils/hooks/use-gdds';
import { useIsCC } from 'utils/hooks/use-is-cc';
import { useCiamLogin } from 'utils/hooks/useCiam';
import currentLanguageSelector from 'utils/selectors/languageSelectors';
import { ciamProfileUrlSelector, orderHistoryUrlSelector } from 'utils/selectors/globalsSelectors';

function CiamLogin({ addActiveClass, opened, ...props }) {
  const language: string = useSelector(currentLanguageSelector);
  const ciamProfileUrl: string | null = useSelector(ciamProfileUrlSelector);
  const orderHistoryUrl: string | null = useSelector(orderHistoryUrlSelector);

  const isContentCreator = useIsCC();
  const isNord = useNord();
  const isGdds = useGdds();
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const ciamDiv = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    state: { gigyaWindow },
  } = useContext(SessionContext);
  const { loggedIn, logout, login, register } = useCiamLogin(gigyaWindow);

  const toHistory = {
    target: orderHistoryUrl,
    type: 'internal_link',
  };

  const country = language.slice(language.length - 2);

  const handleClickOutside = (event) => {
    if (!isContentCreator && ciamDiv.current && !ciamDiv.current.contains(event.target)) {
      setIsOpen(false);
      if (opened && typeof props.toggleCiamFlyout === 'function') props.toggleCiamFlyout(false);
      if (typeof addActiveClass === 'function') addActiveClass('');
    }
  };

  const memoisedHandleClickOutside = useCallback(handleClickOutside, [opened, isContentCreator]);

  useEffect(() => {
    document.addEventListener('mousedown', memoisedHandleClickOutside);
    return () => {
      document.removeEventListener('mousedown', memoisedHandleClickOutside);
    };
  }, [opened]);

  const openLogin = useCallback(() => {
    window.sessionStorage.removeItem('ciam_redirect_to');
    window.sessionStorage.removeItem('ciam_redirect_open');
    login(language, `${country}_WebsitePlatform`);
  }, [language]);

  const openRegistration = useCallback(() => {
    register(language, `${country}_WebsitePlatform`);
  }, [language]);

  const ciamProfileLink = {
    target: ciamProfileUrl,
    type: 'internal_link',
    showArrow: false,
  };

  if (isNord || (isGdds && isDesktop)) {
    return (
      <CiamGddsLoginView
        {...props}
        opened={opened}
        openLogin={openLogin}
        openRegistration={openRegistration}
        toHistory={toHistory}
        ciamProfileUrl={ciamProfileUrl}
        logout={logout}
        loggedIn={loggedIn}
        ciamDiv={ciamDiv}
      />
    );
  }

  return (
    <CiamLoginView
      {...props}
      {...{ isMobile, isDesktop, isTablet }}
      setIsOpen={setIsOpen}
      isOpen={isOpen}
      ciamDiv={ciamDiv}
      openLogin={openLogin}
      openRegistration={openRegistration}
      toHistory={toHistory}
      loggedIn={loggedIn}
      logout={logout}
      ciamProfileLink={ciamProfileLink}
    />
  );
}

export default CiamLogin;
