import React, { useContext } from 'react';
import isEmpty from 'lodash.isempty';
import { useSelector } from 'react-redux';

// components
import MainNavigationItem from './MainNavigationItem';
import FirstLevelFlyout from './FirstLevelFlyout';
import NavigationColumn from './NavigationColumn';
import { NavigationContext } from '../../NavigationContext';

// utils
import { campusReferenceUrlSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

// styles
import styles from '../main-navigation.scss';

// types
import type { NavItem } from '../transformers';

type MainNavigationDesktopProps = {
  item: NavItem;
  level: number;
  pathname: string;
  closeFlyout: (...args: unknown[]) => unknown;
  activeFirstLevel: any[];
  setActiveFirstLevel: React.Dispatch<React.SetStateAction<any[]>>;
  handleClick: (currentItem: any, i: any, isLink: any) => void;
  translationToOverview?: string;
  menuPath?: string;
};

const MainNavigationDesktop = ({
  item,
  level,
  closeFlyout,
  activeFirstLevel,
  setActiveFirstLevel,
  translationToOverview,
  handleClick,
  menuPath = '',
  ...props
}: MainNavigationDesktopProps) => {
  const campusReference = useSelector(campusReferenceUrlSelector);
  const translate = useTranslationFunction();
  const catalogTranslation = translate('campus_nav_catalog') || 'Catalog';

  const { state } = useContext(NavigationContext) || {};
  const { clickPath } = state || {};
  const { pathname } = props;

  if (!clickPath) return null;

  const checkNaviEntry = (item) => {
    if (item.url) {
      if (campusReference.length > 1 && item.url.startsWith(campusReference)) {
        return item.url === pathname || item.label === catalogTranslation;
      }
      if (item.url.split('/')[1] === pathname.split('/')[1]) {
        return true;
      }
    }

    if (item.children) {
      return item.children.some((child) => checkNaviEntry(child));
    }
    return false;
  };

  const urlMatchesNaviEntry = checkNaviEntry(item);

  const isActiveItem = item && clickPath[level] && item.label === clickPath[level].label;

  const activeFirstLevelItem = level === 0 && urlMatchesNaviEntry;
  const activeClickPathClass = isActiveItem ? styles.activeClickPath : '';

  const renderFlyout = (children) => {
    if (level === 0 && isActiveItem) {
      return (
        <FirstLevelFlyout closeFlyout={closeFlyout} label={item.label}>
          {children}
        </FirstLevelFlyout>
      );
    }

    if (isActiveItem) {
      return (
        <NavigationColumn
          index={level}
          label={clickPath[level].label}
          activeClickPathClass={activeClickPathClass}
        >
          {children}
        </NavigationColumn>
      );
    }

    return children;
  };

  if (!item.label) return null;

  return (
    <div
      className={styles.navigationItem}
      data-label={item.label}
      data-url={item.url}
      data-active-fl={level === 0 ? activeFirstLevelItem : undefined}
    >
      <MainNavigationItem
        {...props}
        activeFirstLevel={activeFirstLevel}
        setActiveFirstLevel={setActiveFirstLevel}
        item={item}
        closeFlyout={closeFlyout}
        index={level}
        isActive={isActiveItem}
        handleClick={handleClick}
      />
      {level < 3 &&
        (!campusReference || item.url !== campusReference) &&
        !isEmpty(item.children) &&
        renderFlyout(
          <ul>
            {level > 0 && item.url && (
              <li>
                <MainNavigationItem
                  {...props}
                  isActive={item.url === pathname}
                  item={{ label: translationToOverview, url: item.url }}
                  index={level}
                  handleClick={handleClick}
                />
              </li>
            )}
            {item?.children
              ?.filter((e) => e.label)
              .map((childItem, index) => {
                const Component = !isEmpty(childItem.children)
                  ? MainNavigationDesktop
                  : MainNavigationItem;

                const isActiveClickPathItem =
                  isActiveItem &&
                  clickPath[level + 1] &&
                  clickPath[level + 1].label === childItem.label;

                return (
                  <li key={`${menuPath}${index}-${childItem.label}-${childItem.url}`}>
                    <Component
                      {...props}
                      item={childItem}
                      level={level + 1}
                      index={level + 1}
                      isActive={isActiveClickPathItem}
                      handleClick={handleClick}
                      translationToOverview={translationToOverview}
                      menuPath={`${menuPath}${index}-`}
                    />
                  </li>
                );
              })}
          </ul>,
        )}
    </div>
  );
};

export default React.memo(MainNavigationDesktop);
