import React, { HTMLProps, PropsWithChildren } from 'react';
import { StyledLink } from './nav-item.styles';
import type { UrlObject } from 'url';

type NavItemProps = PropsWithChildren<{
  href: UrlObject | string;
  isOption?: boolean;
  selected?: boolean;
  shallow?: boolean;
  pictogram?: string;
  leftIconName?: string | false;
  rightIconName?: string | false;
  hasPictogram?: boolean;
  withPictogram?: boolean;
  firstLevel?: boolean;
  thirdLevel?: boolean;
  isOverviewItem?: boolean;
  notClickable?: boolean;
}> &
  HTMLProps<HTMLAnchorElement>;

export function NavItem({
  selected,
  pictogram,
  withPictogram,
  shallow,
  children,
  as,
  notClickable,
  ...anchorProps
}: NavItemProps) {
  return (
    <StyledLink
      notClickable={notClickable}
      shallow={shallow}
      className={selected ? 'selected' : undefined}
      selected={selected}
      hasPictogram={withPictogram}
      {...anchorProps}
    >
      {withPictogram && (
        <div className="picto">{pictogram && <img src={pictogram} alt="" />}</div>
      )}
      {children}
    </StyledLink>
  );
}
