import { useEffect } from 'react';
import { Log } from '../../services/log';

export function useBrowserPlugin(store) {
  useEffect(() => {
    const onMessage = (e) => {
      if (
        !store ||
        typeof e.data !== 'object' ||
        typeof e.data.source !== 'string' ||
        typeof e.data.id === 'undefined' ||
        e.data.source !== 'GEBERIT_DEVTOOLS_PLUGIN'
      ) {
        return;
      }

      // we only add the store to window if the devtools are installed
      if (typeof window.geb_devtools_enabled === 'undefined') {
        const style = 'background: #3776C4; border: 0.5rem solid #3776C4; color: #FFF;';
        Log.info('%cGeberit Devtools connected', style);
        Log.debug('%cSupported commands: state, dispatch', style);
        window.geb_devtools_enabled = true;
      }

      let data = null;

      switch (e.data.command) {
        case 'state':
          data = store.getState() || {};
          break;
        case 'dispatch':
          store.dispatch(e.data.data);
          break;
      }

      window.postMessage({
        id: e.data.id,
        source: 'GEBERIT_DEVTOOLS_PAGE',
        data,
      });
    };

    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [store]);
}
