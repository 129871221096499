import { PropsWithChildren, createContext, useContext, useEffect } from 'react';

// utils
import { useNavigationItems } from '../hooks/use-navigation-items';
import { useSelectedFirstLevelIndex } from '../hooks/use-selected-first-level-index';
import { useDesktopFlyoutOpen } from '../mega-menu-provider';

const NavigationContext = createContext({} as ReturnType<typeof useNavigationItems>);

const NavigationIndexContext = createContext({} as ReturnType<typeof useSelectedFirstLevelIndex>);

export function NavigationProvider({ children }: Readonly<PropsWithChildren>) {
  const value = useNavigationItems();
  const { setIsDesktopFlyoutOpen } = useDesktopFlyoutOpen();

  useEffect(() => {
    if (value.navigation?.selectedIndex === undefined) {
      setIsDesktopFlyoutOpen(false);
    }
  }, [value.navigation?.selectedIndex, setIsDesktopFlyoutOpen]);

  useEffect(() => {}, []);

  return (
    <NavigationContext.Provider value={value}>
      <NavigationIndexProvider>{children}</NavigationIndexProvider>
    </NavigationContext.Provider>
  );
}

function NavigationIndexProvider({ children }: Readonly<PropsWithChildren>) {
  const value = useSelectedFirstLevelIndex();

  return (
    <NavigationIndexContext.Provider value={value}>{children}</NavigationIndexContext.Provider>
  );
}

export function useNavigation() {
  return useContext(NavigationContext);
}

export function useNavigationIndex() {
  return useContext(NavigationIndexContext);
}
