import { useCallback, useContext } from 'react';
import queryString from 'query-string';
import isEmpty from 'lodash.isempty';
import { useSelector } from 'react-redux';

import {
  SessionContext,
  SET_SESSION_LOADING,
  addCiamHeader,
} from '../../components/Session/SessionContext';
import { mergeCart } from '../../components/ShoppingBasket/actions';
import { campusDashboardUrlSelector, ciamSelector } from '../selectors/globalsSelectors';
import { useGetEndpoint } from './use-endpoint';
import { CookieStorage } from 'components/Session/CookieStorage';
import { Log } from '../../services/log';

/**
 * Language preparation for ciam
 * @param String lang
 */
const getLanguage = (lang: string) => {
  const langParams = lang.split('_');
  return langParams.length > 1 ? langParams[0] : lang;
};

/**
 * Get ciam token
 */
export function useCiamToken() {
  return CookieStorage.getItem('ciam_jwt');
}

/**
 * Get campus token
 */
export function useCampusToken() {
  const {
    state: {
      campus: { refreshToken, loading },
    },
  } = useContext(SessionContext);

  return { campusToken: refreshToken, loading };
}

/**
 * user ciam token
 * @returns {Object} gigya method hooks and state object
 */
export function useCiamLogin(gigyaWindow, lang) {
  const hasCampus = useSelector(campusDashboardUrlSelector);
  const ciamObject = useSelector(ciamSelector);
  const { getEndpoint } = useGetEndpoint();

  const {
    state: {
      session,
      ciam,
      campus: { refreshToken },
    },
    redirectUrl,
    dispatch: sessionDispatch,
  } = useContext(SessionContext);

  const init = useCallback(async () => {
    if (gigyaWindow) {
      const query = queryString.parse(window.location.search);

      if (!isEmpty(hasCampus) && query.access_token && query.refresh_token) {
        sessionDispatch({ type: SET_SESSION_LOADING, isLoading: true });
        try {
          await new Promise<void>((resolve) => {
            gigyaWindow.socialize.getUserInfo({
              format: 'jsonp',
              callback: ({ UID }) => {
                addCiamHeader(gigyaWindow)
                  .then(() => {
                    mergeCart(getEndpoint, session, UID, lang)
                      .then(() => {
                        sessionDispatch({ type: SET_SESSION_LOADING, isLoading: false });
                      })
                      .catch()
                      .finally(resolve);
                  })
                  .catch(resolve);
              },
            });
          });
        } catch (error) {
          Log.error(error);
        }

        delete query.access_token;
        delete query.refresh_token;
        let newParams = queryString.stringify(query);
        if (newParams) newParams = `?${newParams}`;
        window.history.replaceState(null, null, `${window.location.pathname + newParams}`);

        // open ciam redirect
        const redirectLatest = window.sessionStorage.getItem('ciam_redirect_to');
        if (redirectLatest) {
          const redirectMode = window.sessionStorage.getItem('ciam_redirect_open');

          window.sessionStorage.removeItem('ciam_redirect_to');
          window.sessionStorage.removeItem('ciam_redirect_open');

          if (redirectMode) {
            Object.assign(document.createElement('a'), {
              target: redirectMode,
              href: redirectLatest,
            }).click();
          }
        }
      }
    }
  }, [gigyaWindow, session, lang]);

  const login = (language: string, regsource: string, redirectURL?: string) => {
    if (typeof window !== 'undefined' && window.gigya) {
      redirectUrl.current = redirectURL;
      const langParam = ciamObject?.ciamOverwriteLangCode
        ? getLanguage(ciamObject.ciamOverwriteLangCode)
        : getLanguage(language);
      window.gigya.accounts.showScreenSet({
        screenSet: 'Geberit-RegistrationLogin',
        startScreen: 'geberit-login-nosc',
        regSource: regsource,
        lang: String(langParam).toLowerCase(),
        context: { country: language },
      });
    }
  };

  const logout = () => {
    if (typeof window !== 'undefined' && window.gigya) {
      window.gigya.accounts.logout();
    }
  };

  return {
    init,
    loggedIn: !!ciam,
    campusLoggedIn: Boolean(hasCampus && refreshToken),
    profile: ciam,
    logout,
    role: ciam?.role,
    login,
  };
}
