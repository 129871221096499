import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useStore } from 'react-redux';

export function useSnap() {
  const store = useStore<AppState>();
  const router = useRouter();
  const snap = useRef<Promise<any> | null>(null);

  useEffect(() => {
    if (!snap.current) {
      snap.current = import('./tpp').then((p) => new p.default(router, store));
    } else {
      snap.current.then((snap) => {
        snap.router = router;
        snap.store = store;
      });
    }
  }, [router, store]);
}
