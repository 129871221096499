import React, { HTMLProps, PropsWithChildren } from 'react';
import { Icon } from '@geberit/gdds';
import { StyledLink } from './nav-item.styles';
import type { UrlObject } from 'url';

type NavItemProps = PropsWithChildren<{
  href: UrlObject | string;
  isOption?: boolean;
  selected?: boolean;
  shallow?: boolean;
  leftIconUrl?: string | false;
  leftIconName?: string | false;
  rightIconName?: string | false;
  hasPictogram?: boolean;
  withPictogram?: boolean;
  firstLevel?: boolean;
  thirdLevel?: boolean;
  isOverviewItem?: boolean;
  notClickable?: boolean;
  highlighted?: boolean;
}> &
  HTMLProps<HTMLAnchorElement>;

export function NavItem({
  selected,
  leftIconName,
  rightIconName,
  shallow,
  children,
  isOption,
  highlighted,
  as,
  notClickable,
  ...anchorProps
}: NavItemProps) {
  function getIconPosition() {
    if (leftIconName && rightIconName) {
      return 'both';
    }
    if (leftIconName) {
      return 'left';
    }
    if (rightIconName) {
      return 'right';
    }
    return 'none';
  }

  return (
    <StyledLink
      notClickable={notClickable}
      shallow={shallow}
      isOption={isOption}
      className={selected ? 'selected' : undefined}
      selected={selected}
      highlighted={highlighted}
      $iconPosition={getIconPosition()}
      {...anchorProps}
    >
      {leftIconName && <Icon className="first-icon" symbol={leftIconName} />}
      {children}
      {rightIconName && <Icon className="last-icon" symbol={rightIconName} />}
    </StyledLink>
  );
}
