import { combineReducers } from 'redux';

import downloadCenterReducer from './features/download-center/reducer';
import footerReducer from './components/Footer/reducer';
import globalsReducer from './features/browser-plugin/reducer';
import navigationReducer from './components/Navigation/reducer';
import pageReducer from './components/Page/reducer';
import productCatalogsReducer from './components/Product/reducer';
import translationReducer from './components/Translation/reducer';
import searchReducer from './components/Search/reducer';
import formReducer from './components/ContentElements/Form/reducer';
import basketReducer from './components/ShoppingBasket/reducer';
import webshopReducer from './components/Checkout/reducer';
import eventsReducer from './components/ContentElementsGdds/InvestorsCalendar/reducer';

const defaultReducer = (state: never) => state || {};

const createReducers = () =>
  combineReducers({
    footerNavigation: footerReducer,
    globals: globalsReducer,
    downloadCenter: downloadCenterReducer,
    homepageList: defaultReducer,
    search: searchReducer,
    navigation: navigationReducer,
    page: pageReducer,
    productCatalogs: productCatalogsReducer,
    translations: translationReducer,
    form: formReducer,
    basket: basketReducer,
    webshop: webshopReducer,
    maintenance: defaultReducer,
    events: eventsReducer,
  });

export default createReducers;
