import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { Spinner } from '@geberit/gdds';

// styles
import styles from './ciamlogin.scss';

// components
import {
  BUTTON_TYPES,
  BUTTON_COLORS,
  BUTTON_BACKGROUND,
  BUTTON_ALIGNMENT,
  Button,
} from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import useIsCampus from 'features/header/utils/use-is-campus';
import { siteShortcutTrackingAction } from 'features/header/trackingActions';
import { classNameBuilder } from 'utils/classNameBuilder';
import { webshopEnabledSelector } from 'utils/selectors/globalsSelectors';
import { webshopMaintenanceEnabledSelector } from 'utils/selectors/maintenanceSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';

interface CiamLoginViewProps {
  classes?: string | null;
  ciamLogoutLabel: string;
  ciamLoginLabel: string;
  loggedIn: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  ciamDiv: React.RefObject<HTMLDivElement>;
  openLogin: () => void;
  ciamProfileLink: any;
  toHistory: any;
  logout: () => Promise<void>;
  webshopEnabled: boolean;
  webshopDowntime: boolean;
}

function CiamLoginView({
  loggedIn,
  isOpen,
  setIsOpen,
  ciamDiv,
  openLogin,
  ciamProfileLink,
  toHistory,
  logout,
  classes = null,
  isMobile = true,
  isTablet = false,
}: Readonly<CiamLoginViewProps>) {
  const webshopEnabled = useSelector(webshopEnabledSelector);
  const webshopDowntime = useSelector(webshopMaintenanceEnabledSelector);
  const track = useTracking();
  const router = useRouter();
  const isCampus = useIsCampus();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const translate = useTranslationFunction();

  useEffect(() => {
    if (!isOpen || !ref.current) return () => {};

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio > 0.9) return;

        const offsetRight = entry.boundingClientRect.right - entry.intersectionRect.right;
        ref.current?.style?.setProperty('--offset-right', `${offsetRight}px`);
      },
      { threshold: 1, rootMargin: '0px -64px 0px 0px' },
    );

    const flyout = ref.current?.querySelector('div:first-child');
    if (flyout) observer.observe(flyout);

    return () => {
      observer.disconnect();
    };
  }, [isOpen]);

  return (
    <div
      ref={ref}
      className={`${styles.ciamHeaderWrapper} ciam ${classes} ${isCampus ? 'campus' : undefined}`}
    >
      <Button
        type={BUTTON_TYPES.PLAIN}
        symbol="user"
        onClick={() => {
          loggedIn ? setIsOpen(!isOpen) : openLogin();
          track.trackEvent(siteShortcutTrackingAction('Login'));
        }}
        className={`${loggedIn ? styles.buttonLoggedIn : ''}`}
        aria-label={`${
          loggedIn
            ? translate('web20_metanavigation_logout')
            : translate('web20_metanavigation_login')
        }`}
      >
        {(isMobile || isTablet) && (
          <Translation
            id={!loggedIn ? 'web20_metanavigation_login' : 'web20_ciam_geberit_profile'}
          />
        )}
      </Button>
      {isOpen && (
        <div
          className={classNameBuilder(styles.ciamLoginWrapper, isCampus && 'campus')}
          ref={ciamDiv}
        >
          <div className={styles.ciamLoggedInWrapper}>
            <Button
              type={BUTTON_TYPES.PLAIN}
              symbol="arrow-link-light-right"
              className={styles.ciamLinkButton}
              onClick={() => {
                router.push(ciamProfileLink.target);
                setIsOpen(false);
              }}
            >
              <Translation id="web20_ciam_geberit_profile" />
            </Button>
            {webshopEnabled && !webshopDowntime && (
              <Button
                type={BUTTON_TYPES.PLAIN}
                symbol="arrow-link-light-right"
                className={styles.ciamLinkButton}
                onClick={() => {
                  router.push(toHistory.target);
                  setIsOpen(false);
                }}
              >
                <Translation id="web20_ciam_order_history" />
              </Button>
            )}
            <Button
              background={BUTTON_BACKGROUND.PRIMARY}
              color={BUTTON_COLORS.WHITE}
              align={BUTTON_ALIGNMENT.CENTER}
              onClick={() => {
                if (isCampus) {
                  setLogoutLoading(true);
                  logout().then(() => {
                    setLogoutLoading(false);
                    setIsOpen(false);
                  });
                } else {
                  logout();
                  setIsOpen(false);
                }
              }}
              disabled={logoutLoading}
            >
              {logoutLoading ? <Spinner inverted /> : <Translation id="web20_ciam_logout" />}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CiamLoginView;
