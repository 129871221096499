import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { Icon, Button } from '@geberit/gdds';

// styles
import styles from './main-navigation.scss';

// components
import Logo from '../../Logo/Logo';
import MainNavigatonDesktop from './Desktop/MainNavigatonDesktop';
import Search from '../Search/Search';
import MainNavigationMobile from './Mobile/MainNavigationMobile';
import MainNavigationMobileToggle from './Mobile/Toggle/MainNavigationMobileToggle';
import { NAVIGATION_OPEN, NavigationContext } from '../NavigationContext';
import CiamLogin from '../MetaNavigation/ciam-login';
import LanguageSelector from '../LanguageSelector/LanguageSelector';

// utils
import { groupWorldwideSelector } from 'utils/selectors/globalsSelectors';
import useTouchDetector from 'utils/hooks/use-touch-detector';
import { useNord } from 'utils/hooks/use-nord';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useGdds } from 'utils/hooks/use-gdds';
import { useHeaderPin } from 'utils/hooks/use-header-pin';
import { NavItems } from './transformers';
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useGroup } from 'utils/hooks/use-group';

interface MainNavigationViewProps {
  isSticky?: boolean;
  isDesktop?: boolean;
  page?: Record<string, unknown> | null;
  homepage?: Record<string, unknown> | null;
  navBorderRef: React.Ref<HTMLDivElement>;
  navigation?: NavItems | null;
  wrapperRef: React.Ref<HTMLDivElement> | undefined;
  pageType?: string | null;
  isCampus?: boolean;
  campusEmployee?: boolean;
  closeFlyout: () => void;
  handleClick: (currentItem: any, i: any, isLink: any) => void;
  pathname: string;
  activeFirstLevel: any[];
  setActiveFirstLevel: React.Dispatch<React.SetStateAction<any[]>>;
}

const MainNavigationView = ({
  isSticky,
  isDesktop = false,
  isCampus = false,
  page = null,
  homepage = null,
  navBorderRef,
  wrapperRef,
  navigation = null,
  pageType = null,
  campusEmployee = false,
  closeFlyout,
  handleClick,
  pathname,
  activeFirstLevel,
  setActiveFirstLevel,
}: MainNavigationViewProps) => {
  const { state } = useContext(NavigationContext) || {};
  const [flyoutClasses, setFlyoutClasses] = useState<string[]>([]);
  const worldwideUrl = useSelector(groupWorldwideSelector);
  const isGroup = useGroup();
  const lang = useCurrentLanguage();
  const translationToOverview = useTranslationByKey('web20_menu_tooverview');
  const router = useRouter();
  const headerPin = useHeaderPin();
  const isGdds = useGdds();
  const isTouch = useTouchDetector() && isGdds;
  const isNord = useNord();

  const worldwideClick = () => {
    router.push(worldwideUrl);
  };

  useEffect(() => {
    let isMounted = true;
    let timeout: Timeout | undefined;
    if (state && state.flyoutState === NAVIGATION_OPEN) {
      setFlyoutClasses((classes) =>
        !classes?.includes('flyout--open') ? [...classes, 'flyout--open'] : classes,
      );
      timeout = setTimeout(() => {
        setFlyoutClasses((classes) =>
          !classes.includes(styles.flyoutKeep) ? [...classes, styles.flyoutKeep] : classes,
        );
      }, 400);
    } else {
      setFlyoutClasses((classes) =>
        classes.includes('flyout--open') ? ['flyout--border'] : classes,
      );
      timeout = setTimeout(() => {
        if (isMounted) setFlyoutClasses([]);
      }, 600);
    }

    return () => {
      isMounted = false;
      if (timeout) clearTimeout(timeout);
    };
  }, [state]);

  return (
    <nav className={`${isNord ? styles.nordWidth : ''} grid-container`}>
      <div
        className={classNameBuilder(
          styles.mainNavigation,
          isTouch && styles.isTouch,
          flyoutClasses.join(' '),
        )}
      >
        <Logo homepage={homepage} />
        {isDesktop && !isGroup ? (
          <>
            <div
              className={`${styles.itemsWrapper} ${isCampus ? styles.campusMain : undefined}`}
              ref={wrapperRef}
            >
              {navigation?.map((item, index) => (
                <MainNavigatonDesktop
                  pathname={pathname}
                  item={item}
                  level={0}
                  key={`navbar-${index}`}
                  activeFirstLevel={activeFirstLevel}
                  setActiveFirstLevel={setActiveFirstLevel}
                  handleClick={handleClick}
                  closeFlyout={closeFlyout}
                  translationToOverview={translationToOverview}
                />
              ))}
              <div
                className={classNameBuilder(styles.navBorder, isNord && styles.bgNordics)}
                ref={navBorderRef}
              />
            </div>
            {isCampus ? (
              <div className={styles.campusMeta}>
                {!campusEmployee && <CiamLogin isCampus />}
                <LanguageSelector isCampus />
              </div>
            ) : (
              <div className={styles.navRight}>
                {headerPin.enabled && headerPin.entries[0] && (
                  <Link
                    className={styles.locatorPin}
                    href={headerPin.entries[0]?.url ?? ""}
                    aria-label={headerPin.entries[0].ariaLabel}
                  >
                    <Icon width="1.25rem" withHitSpace symbol="Location"></Icon>
                  </Link>
                )}
                {page && page.type !== 'search_results' && lang !== 'RU_RU' && (
                  <Search stickyHeaderShown={isSticky} />
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.iconWrapper}>
              {worldwideUrl && isGroup && (
                <Button symbol="Globe" stylingType="flat" onClick={worldwideClick} isIcon />
              )}
              {!isCampus &&
                page &&
                page.type !== 'search_results' &&
                !['RU_RU', 'RU_BY'].includes(lang) && (
                  <Search isMobile stickyHeaderShown={isSticky} />
                )}
            </div>
            <MainNavigationMobileToggle />
            <MainNavigationMobile
              handleClick={handleClick}
              translationToOverview={translationToOverview}
              items={navigation}
              isCampus={isCampus}
              pageType={pageType}
              closeFlyout={closeFlyout}
            />
          </>
        )}
      </div>
    </nav>
  );
};

export default MainNavigationView;
