import { Reducer } from 'react';
import { FooterAction } from './actions';

type FooterNavigationState = AppState['footerNavigation'];

const reducer: Reducer<FooterNavigationState, FooterAction> = (
  state: FooterNavigationState,
  action: FooterAction,
) => {
  if (action.type === 'footer/UPDATE') {
    return action.payload;
  }

  return state || {};
};

export default reducer;
