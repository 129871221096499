import { useRef, useState, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { useSelect } from 'downshift';
import { Button } from '@geberit/gdds';

// components
import { NavItem } from '../switch/nav-item';
import { NavBarLocatorSelector } from './nav-bar-locator-selector';

// contexts

// styles
import { AnimationWrapperIcons, IconContainer, LocatorPinContainer } from '../navbar/navbar.styles';

// utils
import { useOutsideClick } from 'utils/hooks/use-outside-click';
import { useHeaderPin } from 'utils/hooks/use-header-pin';
import { useIsDesktop } from 'components/App/SizeProvider';
import {
  useLocatorPageOpen,
  useNavOpen,
  useSearchOpen,
  useDesktopFlyoutOpen,
} from '../mega-menu-provider';
import { useLocatorConfiguration } from 'components/ContentElementsGdds/locator/utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useIsCC } from 'utils/hooks/use-is-cc';
import { EditWrapper } from 'components/ContentCreator/EditWrapper';
import { LocatorFlyoutContainer } from './locator-flyout.styles';
import { TPP_TYPE_TECHNICAL } from 'components/ContentCreator/tppConstants';
import { useReactKeys } from '../language-switch/use-react-keys';
import { useOnEscapePress } from '../hooks/use-key-press';

type LocatorPinProps = {
  showOnlyIcons: boolean;
  isIcon: boolean;
};

type Item = {
  url: string;
  label: string;
  ariaLabel?: string;
};

export function LocatorPin({ showOnlyIcons, isIcon }: Readonly<LocatorPinProps>) {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
  const { isSearchOpen } = useSearchOpen();
  const { setIsNavOpen } = useNavOpen();
  const { enabled, entries = [], previewId } = useHeaderPin();
  const { isLocatorPageOpen, setIsLocatorPageOpen } = useLocatorPageOpen();
  const ref = useRef<HTMLDivElement | null>(null);
  const isDesktop = useIsDesktop({ gdds: true });
  const locatorConfig = useLocatorConfiguration();
  const isPreview = useIsPreview();
  const isCC = useIsCC();
  const { setIsDesktopFlyoutOpen } = useDesktopFlyoutOpen();
  const showButton = entries.length > 1 && !isLocatorPageOpen;
  const showLink = entries.length === 1;

  const buttonWrapperRef = useRef<HTMLDivElement>(null);

  const closeFlyout = () => {
    setIsFlyoutOpen(false);
  };

  useOutsideClick(ref, (event) => {
    if (!isPreview || !document.querySelector('.tpp-borders-container')?.contains(event.target)) {
      closeFlyout();
    }
  });
  useOnEscapePress(closeFlyout);

  const router = useRouter();
  const path = router.asPath;

  const [selectedItem, setSelectedItem] = useState<Item | null | undefined>(null);

  useOutsideClick(ref, (event) => {
    if (!isPreview || !document.querySelector('.tpp-borders-container')?.contains(event.target)) {
      closeFlyout();
    }
  });
  useOnEscapePress(closeFlyout);

  const itemsWithChildren = useReactKeys(entries, ['ariaLabel', 'label', 'url']);
  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: itemsWithChildren,
    itemToString: (l) => l?.label ?? '',
    selectedItem,
    onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
      if (newSelectedItem?.url) router.push(newSelectedItem?.url);
      setSelectedItem(newSelectedItem);
    },
  });

  const label = decodingContent(locatorConfig.urlSettings?.label);
  const ariaLabel = decodingContent(locatorConfig.urlSettings?.ariaLabel);

  const selectedIndex = findSelectedItemIndex(itemsWithChildren, path);

  const toggleButtonProps = getToggleButtonProps();
  return (
    <LocatorPinContainer ref={ref} enabled={enabled && !isSearchOpen} selected={isOpen}>
      <IconContainer showOnMobile={showButton}>
        <div ref={buttonWrapperRef}>
          <Button
            isIcon={isIcon}
            symbol="location"
            className={isOpen ? 'selected' : undefined}
            stylingType={showOnlyIcons ? 'iconHighlight' : 'flat'}
            aria-label={ariaLabel}
            {...toggleButtonProps}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              toggleButtonProps.onClick?.(e);
              getOnClickHandler()();
            }}
          >
            <AnimationWrapperIcons showOnlyIcons={showOnlyIcons}>
              <span className="label">{label}</span>
            </AnimationWrapperIcons>
          </Button>
        </div>
      </IconContainer>
      {showLink && (
        <IconContainer>
          <NavItem
            className={classNameBuilder(isFlyoutOpen && 'selected', !showOnlyIcons && 'with-text')}
            leftIconName={'location'}
            href={entries[0].url || ''}
            aria-label={entries[0].ariaLabel}
            onClick={getOnClickHandler()}
          >
            <AnimationWrapperIcons showOnlyIcons={showOnlyIcons}>
              <span className="label">{entries[0].label}</span>
            </AnimationWrapperIcons>
          </NavItem>
        </IconContainer>
      )}
      <LocatorFlyoutContainer {...getMenuProps()} isOpen={isOpen || isFlyoutOpen}>
        <EditWrapper previewId={previewId} editType={TPP_TYPE_TECHNICAL}>
          {itemsWithChildren?.map((item, index) => {
            return (
              <NavItem
                isOption
                selected={index === selectedIndex}
                href={item.url || ''}
                aria-label={decodingContent(item?.ariaLabel)}
                key={item.key}
                leftIconName="checkmark"
                tabIndex={-1}
                highlighted={highlightedIndex === index}
                onClick={() => {
                  setIsDesktopFlyoutOpen(false);
                  setIsNavOpen(false);
                }}
                {...getItemProps({ item, index })}
              >
                {decodingContent(item.label)}
              </NavItem>
            );
          })}
        </EditWrapper>
      </LocatorFlyoutContainer>
      <NavBarLocatorSelector />
    </LocatorPinContainer>
  );

  function getOnClickHandler() {
    return () => {
      if (showButton) {
        if (!isDesktop) {
          setIsLocatorPageOpen(true);
          setIsNavOpen(true);
        }
      } else {
        if (isCC && isDesktop) {
          setIsFlyoutOpen(true);
        }
        setIsNavOpen(false);
      }
    };
  }

  /**
   * find selected item index
   * @example
   * // returns 1
   * findSelectedItemIndex([ {url: '/a/b2'}, {url: '/a/b'}], "a/b/c");
   * @example
   * // returns 0
   * findSelectedItemIndex([ {url: '/a/b2'}, {url: '/a/b'}], "/a/b2/c");
   * @example
   * // returns 1
   * findSelectedItemIndex([ {url: '/a/b'}, {url: '/a/b2'}], "/a/b2/c");
   * @returns {Number} Returns selected item index.
   */
  function findSelectedItemIndex(items: Item[], path: string): number | undefined {
    let itemIndex: number | undefined = undefined;
    items
      .slice()
      .map((item, itemIndex) => ({ ...item, itemIndex }))
      .sort((a, b) => a.url.length - b.url.length)
      .forEach((item) => {
        if (removeLeadingAndTrailingSlash(path).includes(removeLeadingAndTrailingSlash(item.url))) {
          itemIndex = item.itemIndex;
        }
      });

    return itemIndex;
  }

  function removeLeadingAndTrailingSlash(url: string): string {
    return url?.replace(/(^\/)|(\/$)/g, '');
  }
}
