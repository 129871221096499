import { MouseEvent, useEffect, useRef } from 'react';
import { Button, Container as GridContainer } from '@geberit/gdds';

// components
import { SecondLevelNavigation } from '../second-level/second-level-navigation';

// styles
import { GridCol, NavItemContainer } from 'mm/common/common.styles';
import { DesktopFlyout, FlyoutInner, MainNavDesktopContainer } from '../common.styles';
import { CloseIcon } from 'mm/navbar/navbar.styles';
import { StyledLink } from './nav-item.styles';

// utils
import { useDesktopFlyoutOpen } from 'mm/mega-menu-provider';
import type { PathNode } from 'mm/hooks/use-navigation-items';
import { navigationTrackingAction } from 'components/Navigation/trackingActions';
import { useTracking } from 'utils/hooks/useTracking';
import { useNavigation } from 'mm/mainnav/navigation-provider';
import { useReactKeys } from 'mm/language-switch/use-react-keys';
import { useElementHeight } from 'components/ContentElementsGdds/table/hooks/useElementHeight';
import useIsCampus from 'features/header/utils/use-is-campus';
import { decodingContent } from 'utils/decodingContent';

type NavigationItemsProps = {
  pathNode?: PathNode;
  navItemRefs?: React.MutableRefObject<any[]>;
};

export function FirstLevelNavigationItems({ pathNode }: Readonly<NavigationItemsProps>) {
  const { pushPathNode, findPath } = useNavigation();
  const track = useTracking();
  const wrapperRef = useRef(null);
  const isCampus = useIsCampus();

  const processedChildren = pathNode?.children;
  const childrenWithKeys = useReactKeys(processedChildren ?? [], ['ariaLabel', 'label', 'url']);

  const { isDesktopFlyoutOpen, setIsDesktopFlyoutOpen } = useDesktopFlyoutOpen();

  const ulHeight = useElementHeight(wrapperRef);

  useEffect(() => {
    const root = document.documentElement;

    root.style.setProperty('--first-level-nav-height', `${ulHeight}px`);
  }, [ulHeight]);

  return (
    <MainNavDesktopContainer className="first-level" ref={wrapperRef}>
      {childrenWithKeys.map((navItem) => {
        const isSelected = isNavItemSelected(pathNode!, navItem.originalIndex);
        const notClickable = isSelected && isDesktopFlyoutOpen;

        return (
          <NavItemContainer key={navItem.key}>
            <StyledLink
              key={navItem.key}
              shallow={!navItem.url}
              href={navItem?.url || '/'}
              className={isSelected ? 'selected' : undefined}
              aria-label={navItem?.ariaLabel}
              onClick={getNavItemClickHandler(
                { ...navItem, depth: pathNode!.depth + 1 },
                navItem.originalIndex,
                navItem.url,
              )}
              notClickable={notClickable}
            >
              {decodingContent(navItem.label ?? '')}
            </StyledLink>
            {
              <DesktopFlyout visible={isSelected && navItem.children} show={isDesktopFlyoutOpen}>
                {isSelected && navItem.children && (
                  <FlyoutInner>
                    <GridCol />
                    <GridContainer maxContentWidth="80rem">
                      <SecondLevelNavigation />
                      <CloseIcon>
                        <Button
                          onClick={() => {
                            setIsDesktopFlyoutOpen(false);
                          }}
                          tabIndex={isDesktopFlyoutOpen ? 0 : -1}
                          isIcon
                          symbol="close"
                          stylingType="icon"
                        />
                      </CloseIcon>
                    </GridContainer>
                    <GridCol />
                  </FlyoutInner>
                )}
              </DesktopFlyout>
            }
          </NavItemContainer>
        );
      })}
    </MainNavDesktopContainer>
  );

  function isNavItemSelected(pathNode: PathNode, index: number): boolean | undefined {
    // in desktop, navigation stands only for the first level,
    // so index === navigation?.selectedIndex means that the element is selected
    // secondLevelNavigation & thirdLevelNavigation is for second/third level
    return index === pathNode?.selectedIndex;
  }

  function handlePathNodeChildren(pathNode: PathNode, index: number) {
    // A click on navigation level 1 does not cause a url change
    // and our hook to get the treePath is not triggered automatically
    // so we have to trigger the function to get the treePath manually
    findPath();
    pushPathNode({ ...pathNode }, index);
  }

  function getNavItemClickHandler(pathNode: PathNode, index: number, url: string) {
    return (e: MouseEvent<HTMLAnchorElement>) => {
      if (isCampus) {
        e.preventDefault();
        // router.push(url) not in use bacause of unwanted scrollbehavior on catalogpage
        window.location.href = url;
        return;
      }

      // If on mobile and item has children, don't preload page in the background
      if (pathNode.children) {
        e.preventDefault();
        handlePathNodeChildren(pathNode, index);
      }

      setIsDesktopFlyoutOpen(Boolean(pathNode.children));
      if (url) {
        track.trackEvent(navigationTrackingAction(pathNode.label));
      }
    };
  }
}
