import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import sortBy from 'lodash.sortby';

// compoenents
import LanguageSelectorDesktop from './LanguageSelectorDesktop';

// utils
import {
  alternativePageLanguagesSelector,
  currentPageTypeSelector,
} from 'utils/selectors/pageSelectors';
import LanguageSelectorMobile from './LanguageSelectorMobile';
import { useTracking } from 'utils/hooks/useTracking';
import { languageSwitchTrackingAction } from '../trackingActions';
import useTouchDetector from 'utils/hooks/use-touch-detector';
import { useIsDesktop } from 'components/App/SizeProvider';
import { useIsPreview } from 'utils/hooks/useIsPreview';
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { isEmpty } from 'utils/is-empty';

interface LanguageSelectorProps {
  isCampus?: boolean;
}

export default function LanguageSelector({ isCampus = false }: Readonly<LanguageSelectorProps>) {
  const isDesktop = useIsDesktop();
  const track = useTracking();
  const isTouch = useTouchDetector();
  const router = useRouter();
  const isPreview = useIsPreview();
  const pageType = useSelector(currentPageTypeSelector);
  const language = useCurrentLanguage();
  const alternativePageLanguages = useSelector(alternativePageLanguagesSelector) ?? [];
  if (isEmpty(language) || pageType === 'search_results') return null;

  const publicLanguages = sortBy(
    alternativePageLanguages.filter((l) => l.hidden === false),
    'label',
  );
  const privateLanguages = sortBy(
    alternativePageLanguages.filter((l) => l.hidden === true),
    'label',
  );
  const sortedLanguages = isPreview ? publicLanguages.concat(privateLanguages) : publicLanguages;

  if (isEmpty(sortedLanguages) || sortedLanguages.length <= 1) {
    return null;
  }

  const clickHandler = (lang, url = null) => {
    if (!isEmpty(url) && router && pageType !== 'error_page') {
      router.push(url);
    }
    track.trackEvent(languageSwitchTrackingAction(lang));
  };

  const Component = isDesktop && !isTouch ? LanguageSelectorDesktop : LanguageSelectorMobile;

  return (
    <Component
      sortedLanguages={sortedLanguages}
      language={language}
      pageType={pageType}
      isPreview={isPreview}
      clickHandler={clickHandler}
      isCampus={isCampus}
    />
  );
}
