import React from 'react';
import isEmpty from 'lodash.isempty';
import key from 'weak-key';
import { useRouter } from 'next/router';

// components
import { CmsLink } from 'components/Link/CmsLink';
import { EditWrapper } from 'components/ContentCreator/EditWrapper';

// styles
import styles from './meta-navigation.scss';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { metaNavigationTrackingAction } from '../trackingActions';

interface MetaNavigationLinksProps {
  previewId: string | null;
  links:
    | {
        target: string;
        text: string;
        type: string;
        window: string;
      }[]
    | null;
}

function MetaNavigationLinks({
  previewId = null,
  links = null,
}: Readonly<MetaNavigationLinksProps>) {
  const track = useTracking();
  const router = useRouter();

  return (
    <EditWrapper previewId={previewId} editType="meta_navigation">
      <nav className={styles.headerLinks}>
        <ul>
          {!isEmpty(links) &&
            links.map((link) => {
              const isActive = router.asPath === link.target ? styles.active : '';
              return (
                <li key={key(link)} className={isActive}>
                  <CmsLink
                    link={{
                      target: link.target,
                      type: link.type,
                      text: link.text,
                      window: link.window,
                    }}
                    className={styles.link}
                    tracking={() => track.trackEvent(metaNavigationTrackingAction(link.text))}
                  />
                </li>
              );
            })}
        </ul>
      </nav>
    </EditWrapper>
  );
}

export default MetaNavigationLinks;
