import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { currentPageSelector } from 'utils/selectors/pageSelectors';

export default function useIsCampus() {
  const page = useSelector(currentPageSelector);

  const isCampus = useMemo(() => {
    return Boolean(page?.type?.includes('campus') && page.type !== 'campus_landingpage');
  }, [page]);

  return isCampus;
}
